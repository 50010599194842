import { FormControl } from 'native-base';

export function EmptyIngredientsFiller() {
  return (
    <>
      <FormControl.Label my={4}>Lista de ingredientes</FormControl.Label>
      <FormControl.HelperText>
        Salve sua receita abaixo para adicionar ingredientes.
      </FormControl.HelperText>
    </>
  );
}
