type Dimension = string;

interface ConversionMap {
  [key: string]: { [key: string]: number };
}

// This map tells how many units of the second key has in 1 unit of the first
// key. For instance: 1 kg has 1000 g.
const conversionMap: ConversionMap = {
  kg: {
    g: 1000,
  },
  g: {
    kg: 0.001,
  },
  l: {
    ml: 1000,
  },
  ml: {
    l: 0.001,
  },
};

class RationNotFoundError extends Error {}

export function getRatioBetweenDimensions(
  fromDimension: Dimension,
  toDimension: Dimension
): number {
  const normalizedFromDimension = normalize(fromDimension);
  const normalizedToDimension = normalize(toDimension);
  if (normalizedFromDimension === normalizedToDimension) {
    return 1;
  }
  const ratio =
    conversionMap?.[normalizedFromDimension]?.[normalizedToDimension] ?? null;
  return ratio;
}

// XXX RationNotFoundError is still necessary?
getRatioBetweenDimensions.RationNotFoundError = RationNotFoundError;

function normalize(dimension: Dimension) {
  return dimension.trim().toLowerCase();
}
