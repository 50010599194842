import Svg, { SvgProps, Path, PathProps } from 'react-native-svg';

interface ArrowsSvgProps extends SvgProps {
  left?: PathProps;
  right?: PathProps;
}

const ArrowsSvg = ({
  left = { strokeWidth: 2 },
  right = { strokeWidth: 2 },
  ...rest
}: ArrowsSvgProps) => (
  <Svg width="100%" height="100%" viewBox="0 0 440 241" fill="none" {...rest}>
    <Path
      d="M145.774 225.761L246.654 133.872C257.782 123.728 257.782 106.206 246.654 96.0623L145.774 4.23876C135.441 -5.18072 118.815 2.13091 118.815 16.0296V52.2427H0V177.757H118.815V213.97C118.815 227.869 135.441 235.181 145.774 225.761Z"
      {...left}
    />
    <Path
      d="M391.026 238.139L435.334 197.787C440.222 193.333 440.222 185.638 435.334 181.184L391.026 140.861C386.488 136.725 379.185 139.936 379.185 146.039V161.941H327V217.059H379.185V232.961C379.185 239.064 386.488 242.275 391.026 238.139Z"
      {...right}
    />
  </Svg>
);

export default ArrowsSvg;
