import type { ComponentProps } from 'react';
import { Input } from 'native-base';
import RNCurrencyInput from 'react-native-currency-input';

type CurrencyInputProps = Omit<
  ComponentProps<typeof Input>,
  'value' | 'onChangeText'
> & {
  value: unknown;
  onChangeText: (value: number | null) => void;
};

export function CurrencyInput({
  value,
  accessibilityLabel,
  onChangeText,
  onBlur,
}: CurrencyInputProps) {
  return (
    <RNCurrencyInput
      value={typeof value === 'number' ? Number(value) : null}
      onChangeValue={(value) =>
        onChangeText(typeof value === 'number' ? Number(value) : null)
      }
      onBlur={onBlur}
      renderTextInput={(textInputProps) => (
        <Input accessibilityLabel={accessibilityLabel} {...textInputProps} />
      )}
      prefix="R$ "
      delimiter="."
      separator=","
      precision={2}
    />
  );
}
