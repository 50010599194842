import { useCallback } from 'react';
import { FlatList } from 'native-base';
import { useNavigation } from '@react-navigation/native';

import {
  AlertIcon,
  ListItem,
  ListLoadingSkeleton,
  EmptyStateFillerImage,
} from '@sizeup/components';
import { toCurrency } from '@sizeup/utils';

import { ProductsActions } from '.';
import { useProducts } from '../hooks';

interface ProductsListProps {
  selectedProductId?: string;
}

export function ProductsList({ selectedProductId }: ProductsListProps) {
  const productsState = useProducts();
  return (
    <>
      <ProductsListItems
        selectedProductId={selectedProductId}
        {...productsState}
      />
      <ProductsActions isEmptyState={productsState.empty} />
    </>
  );
}

function ProductsListItems({
  products,
  fetching,
  loading,
  empty,
  selectedProductId,
}: ReturnType<typeof useProducts> & ProductsListProps) {
  const { navigate } = useNavigation();

  const renderProductItem = useCallback(
    ({ item }: { item: NonNullable<typeof products>[number] }) => (
      <ListItem
        testID={`product-item-${item.productId}`}
        key={item.productId}
        text={item.name || 'Produto'}
        subText={item.createdAt?.toLocaleDateString()}
        rightText={toCurrency(item.salesPrice)}
        rightSubText={toCurrency(item.costPrice)}
        rightSubIcon={item.hasErrors ? <AlertIcon /> : undefined}
        isSelected={!!selectedProductId && selectedProductId === item.productId}
        onPress={() => navigate('ProductUpdate', { id: item.productId })}
        showChevron
      />
    ),
    [navigate, selectedProductId]
  );

  const keyExtractor = useCallback(
    (item: NonNullable<typeof products>[number]) => item.productId,
    []
  );

  if (loading) {
    return <ListLoadingSkeleton />;
  }

  if (empty) {
    return <EmptyStateFillerImage image="Products" />;
  }

  return (
    <FlatList
      flex={1}
      refreshing={fetching}
      data={products}
      renderItem={renderProductItem}
      keyExtractor={keyExtractor}
    />
  );
}
