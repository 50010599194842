import { Box, Icon, Stack, Image, Text, VStack, useTheme } from 'native-base';

import MaterialIcons from '@expo/vector-icons/MaterialIcons';

import { LogoImage } from '@sizeup/theme/images';
import IconBarcodeScanSvg from '../../../assets/svgs/IconBarcodeScanSvg';
import ArrowsSvg from '../../../assets/svgs/ArrowsSvg';

const WelcomeHeroImage = require('../../../assets/welcomeHeroImage.png'); // eslint-disable-line @typescript-eslint/no-var-requires

export function HomeEmptyState() {
  const { colors } = useTheme();

  return (
    <Box flex={1} safeAreaTop>
      <Image
        source={LogoImage}
        resizeMode="contain"
        w="100%"
        h={['60px', '60px']}
        mt={[0, 20]}
        mb={6}
        zIndex={1}
        alt="logo image"
      />
      <Stack
        flex={1}
        direction={['column', 'column', 'column', 'row']}
        alignItems="center"
        p={[5, 0]}
      >
        <Box justifyContent="center" alignItems="center" top={-20}>
          <Box w={[480, 600]} h={[230, 400]} top={-20} left={-30}>
            <ArrowsSvg
              left={{ fill: colors.primary[600] }}
              right={{ fill: undefined, stroke: colors.secondary[700] }}
            />
          </Box>
          <Box position="absolute">
            <Image
              source={WelcomeHeroImage}
              size={[200, 290]}
              alt="welcome image"
            />
          </Box>
        </Box>

        <VStack space={['8', '24']} flex={1}>
          <VStack alignItems="center" space={['3', '5']}>
            <Icon
              as={MaterialIcons}
              name="restaurant-menu"
              size={12}
              color="black"
            />
            <Text fontSize={['sm', 'sm', 'lg']} textAlign="center">
              Tenha as fichas técnicas de seu restaurante {'\n'}atualizadas e na
              palma da mão
            </Text>
          </VStack>

          <VStack alignItems="center" space={['3', '5']}>
            <IconBarcodeScanSvg size={40} />
            <Text fontSize={['sm', 'sm', 'lg']} textAlign="center">
              Adicione suas compras e o custo das {'\n'}receitas é atualizado na
              hora
            </Text>
          </VStack>
        </VStack>
      </Stack>
    </Box>
  );
}
