import { useCallback } from 'react';
import {
  ScrollView,
  Input,
  FormControl,
  HStack,
  VStack,
  Box,
  Select,
} from 'native-base';
import { CurrencyInput } from '@sizeup/components';
import { useForm, Controller } from 'react-hook-form';
import { useFocusEffect } from '@react-navigation/native';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { DimensionOptions } from '@sizeup/types';
import { NumberInput } from '@sizeup/components';
import { AddPurchaseItemActions } from '../components';
import * as formUtils from '../../../utils/forms';

const validationSchema = z.object({
  name: formUtils.notEmptyString(),
  quantity: formUtils.stringAsNumber(),
  dimension: formUtils.notEmptyString(),
  totalCost: formUtils.stringAsNumber(),
  brand: z.string(),
});

type InputSchema = z.input<typeof validationSchema>;
export type FormSchema = z.output<typeof validationSchema>;

interface Props {
  defaultValues: Partial<FormSchema>;
  onSaveAndReturn: (values: FormSchema) => Promise<void>;
  onSaveAndAddAnother: (values: FormSchema) => Promise<void>;
}

export function PurchaseItemForm({
  defaultValues,
  onSaveAndReturn,
  onSaveAndAddAnother,
}: Props) {
  const { control, handleSubmit, watch, reset, formState } = useForm<
    InputSchema,
    unknown,
    FormSchema
  >({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
  });
  const { isValid, isDirty, isSubmitting, isSubmitted } = formState;
  useFocusEffect(
    useCallback(() => {
      if (!isSubmitting && defaultValues) {
        reset(getAllDefaultValues(defaultValues));
      }
    }, [defaultValues, isSubmitting, reset])
  );
  const onSaveAndAddAnotherClick = useCallback(
    async (values: FormSchema) => {
      await onSaveAndAddAnother(values);
    },
    [onSaveAndAddAnother]
  );
  const onSaveAndReturnClick = useCallback(
    async (values: FormSchema) => {
      await onSaveAndReturn(values);
    },
    [onSaveAndReturn]
  );

  const quantity = watch('quantity');
  const formPriceLabel =
    'Preço total' +
    (quantity ? ` (para ${String(quantity)} ${watch('dimension')})` : '');

  return (
    <Box flex={1}>
      <FormControl flex={1}>
        <ScrollView p={7}>
          <FormControl.Label my={4}>Nome do item</FormControl.Label>
          <Controller
            name="name"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <FormControl isInvalid={Boolean(error)}>
                <VStack>
                  <Input
                    size="md"
                    placeholder="Ex.: Ervilha"
                    accessibilityLabel="Nome do item"
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value || ''}
                  />
                  {error && (
                    <FormControl.ErrorMessage>
                      {error.message}
                    </FormControl.ErrorMessage>
                  )}
                </VStack>
              </FormControl>
            )}
          />
          <FormControl.Label my={4}>Quantidade</FormControl.Label>
          <HStack space={1}>
            <VStack flex={3}>
              <Controller
                name="quantity"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <FormControl isInvalid={Boolean(error)}>
                    <NumberInput
                      placeholder="Ex.: 500"
                      accessibilityLabel="Quantidade"
                      onChangeText={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                    {error && (
                      <FormControl.ErrorMessage>
                        {error.message}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
              />
            </VStack>
            <VStack>
              <Controller
                name="dimension"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl isInvalid={Boolean(error)}>
                    <Select
                      accessibilityLabel="Dimensão da quantidade"
                      onValueChange={onChange}
                      selectedValue={value}
                      placeholder="Escolha uma opção"
                      minWidth="200"
                      dropdownIcon={<></>}
                    >
                      {DimensionOptions.map(([dimension, label]) => (
                        <Select.Item
                          key={dimension}
                          label={label}
                          accessibilityLabel={label}
                          value={dimension}
                        />
                      ))}
                    </Select>
                    {error && (
                      <FormControl.ErrorMessage>
                        {error.message}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
              />
            </VStack>
          </HStack>
          <FormControl.Label my={4}>{formPriceLabel}</FormControl.Label>
          <Controller
            name="totalCost"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <FormControl isInvalid={Boolean(error)}>
                <VStack>
                  <CurrencyInput
                    accessibilityLabel="Preço total"
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  {error && (
                    <FormControl.ErrorMessage>
                      {error.message}
                    </FormControl.ErrorMessage>
                  )}
                </VStack>
              </FormControl>
            )}
          />
          <FormControl.Label my={4}>Marca do produto</FormControl.Label>
          <Controller
            name="brand"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <FormControl isInvalid={Boolean(error)}>
                <VStack>
                  <Input
                    accessibilityLabel="Marca do produto"
                    onChangeText={onChange}
                    onBlur={onBlur}
                    value={value || ''}
                  />
                  {error && (
                    <FormControl.ErrorMessage>
                      {error.message}
                    </FormControl.ErrorMessage>
                  )}
                </VStack>
              </FormControl>
            )}
          />
        </ScrollView>
      </FormControl>
      <AddPurchaseItemActions
        handleSaveAddAnother={handleSubmit(onSaveAndAddAnotherClick)}
        handleSaveReturn={handleSubmit(onSaveAndReturnClick)}
        isDisabled={!isDirty || !isValid || isSubmitting || isSubmitted}
      />
    </Box>
  );
}

function getAllDefaultValues(values: Partial<FormSchema>) {
  return {
    name: values?.name || '',
    quantity: values?.quantity || '',
    dimension: values?.dimension || '',
    totalCost: values?.totalCost || '',
    brand: values?.brand || '',
  };
}
