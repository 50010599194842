import { useState, useRef, useCallback, useEffect } from 'react';
import Constants from 'expo-constants';
import * as DocumentPicker from 'expo-document-picker';
import { trpc } from '../../../utils/trpc';
import { sleep } from '../../../utils/sleep';
import type { RouterOutput } from '../../../utils/trpc';

const basePollingTimeout = Constants?.expoConfig?.extra?.basePollingTimeout;
const timeoutIncreaseRate = Constants?.expoConfig?.extra?.timeoutIncreaseRate;
const maxPollingAttempts = Constants?.expoConfig?.extra?.maxPollingAttempts;
const timeoutAfterPollingDone =
  Constants?.expoConfig?.extra?.timeoutAfterPollingDone;

export enum InvoiceUploadStage {
  Waiting = 'waiting',
  Processing = 'processing',
  Done = 'done',
  Error = 'error',
}

type IncomingInvoiceId =
  RouterOutput['incomingInvoice']['getOne']['incomingInvoiceId'];

export function useInvoiceUpload() {
  const utils = trpc.useContext();
  const [stage, setStage] = useState(InvoiceUploadStage.Waiting);
  const createIncomingInvoice = useCreateIncomingInvoice();
  const cleanupStageAfterTimeout = useCleanupAfterTimeout(setStage);
  const fetchIncomingInvoice = utils.incomingInvoice.getOne.fetch;
  const fetchIncomingInvoiceById = useCallback(
    (incomingInvoiceId: IncomingInvoiceId) => {
      return fetchIncomingInvoice({ incomingInvoiceId }, { staleTime: 0 });
    },
    [fetchIncomingInvoice]
  );
  const refreshPurchases = utils.purchase.getList.invalidate;
  const handleInvoiceUpload = useCallback(async () => {
    const file = await pickFile();
    if (!file) {
      setStage(InvoiceUploadStage.Error);
      cleanupStageAfterTimeout();
      return;
    }
    setStage(InvoiceUploadStage.Processing);
    let incomingInvoice;
    try {
      incomingInvoice = await createIncomingInvoice();
    } catch (error) {
      setStage(InvoiceUploadStage.Error);
      cleanupStageAfterTimeout();
      return;
    }
    try {
      await uploadInvoice(file, incomingInvoice.uploadData);
    } catch (error) {
      setStage(InvoiceUploadStage.Error);
      cleanupStageAfterTimeout();
      return;
    }
    const purchaseId = await pollPurchaseId(
      fetchIncomingInvoiceById,
      incomingInvoice.incomingInvoiceId
    );
    if (!purchaseId) {
      setStage(InvoiceUploadStage.Error);
      cleanupStageAfterTimeout();
      return;
    }
    refreshPurchases();
    setStage(InvoiceUploadStage.Done);
    cleanupStageAfterTimeout();
  }, [
    createIncomingInvoice,
    fetchIncomingInvoiceById,
    refreshPurchases,
    cleanupStageAfterTimeout,
  ]);

  return { onUpload: handleInvoiceUpload, stage };
}

function useCleanupAfterTimeout(setStage: (stage: InvoiceUploadStage) => void) {
  const cleanupTimeoutId = useRef<ReturnType<typeof setTimeout>>();
  const cleanupStage = useCallback(() => {
    if (cleanupTimeoutId.current) {
      clearTimeout(cleanupTimeoutId.current);
    }
    cleanupTimeoutId.current = setTimeout(
      () => setStage(InvoiceUploadStage.Waiting),
      timeoutAfterPollingDone
    );
  }, [setStage, cleanupTimeoutId]);
  useEffect(
    function cancelStageCleanupOnUnmount() {
      return () => {
        if (cleanupTimeoutId.current) {
          clearTimeout(cleanupTimeoutId.current);
        }
      };
    },
    [cleanupTimeoutId]
  );
  return cleanupStage;
}

function useCreateIncomingInvoice() {
  const mutation = trpc.incomingInvoice.create.useMutation();
  return mutation.mutateAsync;
}

async function pickFile() {
  const fileResult = await DocumentPicker.getDocumentAsync({
    type: ['application/xml', 'text/xml'],
  });
  if (fileResult.type === 'success') {
    return fileResult.file as File;
  }
  return null;
}

async function uploadInvoice(
  file: File,
  uploadData: RouterOutput['incomingInvoice']['create']['uploadData']
) {
  const fields = uploadData.fields;
  const formData = createFormData(file, fields);
  const response = await fetch(uploadData.url, {
    method: 'POST',
    body: formData,
  });
  if (!response.ok) {
    throw new Error('Request failed');
  }
}

async function pollPurchaseId(
  fetchIncomingInvoiceById: (
    incomingInvoiceId: IncomingInvoiceId
  ) => Promise<RouterOutput['incomingInvoice']['getOne']>,
  incomingInvoiceId: IncomingInvoiceId
) {
  let result;
  for (let index = 0; index < maxPollingAttempts; index++) {
    result = await fetchIncomingInvoiceById(incomingInvoiceId);
    if (result.purchaseId) {
      return result.purchaseId;
    }
    await sleep(getPollingTimeout(index));
  }
  return null;
}

function createFormData(file: File, fields: { [key: string]: string }) {
  const formData = new FormData();
  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  formData.append('file', file);
  return formData;
}

function getPollingTimeout(attemptNum: number) {
  return basePollingTimeout ** (1 + attemptNum * timeoutIncreaseRate);
}
