import { useState, useCallback } from 'react';
import { Button } from 'native-base';

export function DeleteSubproduct({
  onClick,
}: {
  onClick: () => Promise<void>;
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    await onClick();
    setIsDeleting(false);
  }, [onClick]);
  return (
    <Button
      variant="secondary"
      colorScheme="blueGray"
      onPress={handleDelete}
      isDisabled={isDeleting}
      isLoading={isDeleting}
    >
      Remover
    </Button>
  );
}
