import { useCallback } from 'react';
import { trpc } from '../../../utils/trpc';

export function useProduct({ productId }: { productId: string }) {
  const productQuery = trpc.product.getOne.useQuery({ productId });
  const refresh = useCallback(() => null, []);
  const product = productQuery.data;
  return {
    product,
    fetching: productQuery.isLoading,
    error: productQuery.error,
    refresh,
  };
}
