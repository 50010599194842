import { useRef, useEffect } from 'react';
import type { ReactNode } from 'react';
import {
  Button,
  FormControl,
  HStack,
  Icon,
  Input,
  Modal,
  VStack,
  Select,
  useTheme,
} from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { DimensionOptions, IngredientCategory } from '@sizeup/types';
import { Pill } from '@sizeup/components';
import * as formUtils from '../../../utils/forms';
import { NumberInput } from '@sizeup/components';

export const validationSchema = z.object({
  name: z.string(),
  initialQuantity: formUtils.stringAsNumber(),
  finalQuantity: formUtils.stringAsNumber(),
  dimension: z.string(),
  type: z.string(),
});

type InputSchema = z.input<typeof validationSchema>;
type FormSchema = z.output<typeof validationSchema>;

export function IngredientForm({
  title,
  visible,
  defaultValues,
  onSave,
  onClose,
  deleteButton,
  nameFieldDisabled = false,
}: {
  visible: boolean;
  title: string;
  defaultValues: InputSchema;
  onSave: (values: FormSchema) => void;
  onClose: () => void;
  deleteButton?: ReactNode;
  nameFieldDisabled?: boolean;
}) {
  const initialRef = useRef(null);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<InputSchema, unknown, FormSchema>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
  });
  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset, visible]);
  const {
    components: { Icon: IconTheme },
  } = useTheme();
  return (
    <Modal
      accessibilityRole="none"
      accessibilityLabel={title}
      accessibilityState={{ expanded: visible }}
      isOpen={visible}
      onClose={onClose}
      initialFocusRef={initialRef}
      size="lg"
    >
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          <VStack space={4}>
            <VStack>
              <Controller
                name="name"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <FormControl isInvalid={Boolean(error)}>
                    <FormControl.Label>Nome</FormControl.Label>
                    <Input
                      ref={nameFieldDisabled ? undefined : initialRef}
                      size="md"
                      accessibilityLabel="Nome"
                      onChangeText={onChange}
                      onBlur={onBlur}
                      value={value}
                      isDisabled={nameFieldDisabled}
                    />
                    {error && (
                      <FormControl.ErrorMessage>
                        {error.message}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
              />
            </VStack>
            <VStack>
              <FormControl>
                <FormControl.Label>Quantidade inicial</FormControl.Label>
                <FormControl.HelperText>
                  Quantidade antes do ingrediente ser manipulado. Exemplo:
                  cebola inteira
                </FormControl.HelperText>
              </FormControl>
              <HStack space={1}>
                <Controller
                  control={control}
                  name="initialQuantity"
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <FormControl flex={1} isInvalid={Boolean(error)}>
                      <VStack>
                        <NumberInput
                          ref={nameFieldDisabled ? initialRef : undefined}
                          accessibilityLabel="Quantidade inicial"
                          flex={3}
                          size="md"
                          placeholder="Ex.: 350"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                        {error && (
                          <FormControl.ErrorMessage testID="initialQuantity-error">
                            {error.message}
                          </FormControl.ErrorMessage>
                        )}
                      </VStack>
                    </FormControl>
                  )}
                />
                <Controller
                  name="dimension"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl flex={1} isInvalid={Boolean(error)}>
                      <VStack>
                        <Select
                          accessibilityLabel="Unidade para quantidade inicial"
                          onValueChange={onChange}
                          selectedValue={value}
                          placeholder="Escolha uma opção"
                          dropdownIcon={emptyDropdownIcon}
                        >
                          <Select.Item label={'Nenhuma dimensão'} value={''} />
                          {DimensionOptions.map(([dimension, label]) => (
                            <Select.Item
                              key={dimension}
                              label={label}
                              value={dimension}
                            />
                          ))}
                        </Select>
                        {error && (
                          <FormControl.ErrorMessage testID="dimension-error">
                            {error.message}
                          </FormControl.ErrorMessage>
                        )}
                      </VStack>
                    </FormControl>
                  )}
                />
              </HStack>
            </VStack>

            <VStack>
              <FormControl>
                <FormControl.Label>Quantidade final</FormControl.Label>
                <FormControl.HelperText>
                  Quantidade depois que o ingrediente é manipulado. Exemplo:
                  cebola descascada e cortada em cubos
                </FormControl.HelperText>
              </FormControl>
              <HStack space={1}>
                <Controller
                  name="finalQuantity"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <FormControl flex={1} isInvalid={Boolean(error)}>
                      <VStack>
                        <NumberInput
                          accessibilityLabel="Quantidade final"
                          flex={3}
                          size="md"
                          placeholder="Ex.: 350"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                        {error && (
                          <FormControl.ErrorMessage testID="finalQuantity-error">
                            {error.message}
                          </FormControl.ErrorMessage>
                        )}
                      </VStack>
                    </FormControl>
                  )}
                />
                <Controller
                  name="dimension"
                  control={control}
                  render={({ field: { value } }) => (
                    <Input
                      accessibilityLabel="Unidade para quantidade final"
                      flex={1}
                      size="md"
                      value={
                        value ? Object.fromEntries(DimensionOptions)[value] : ''
                      }
                      isDisabled
                    />
                  )}
                />
              </HStack>
            </VStack>

            <FormControl.Label>Tipo do ingrediente</FormControl.Label>
            <Controller
              name="type"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl isInvalid={Boolean(error)}>
                  <VStack>
                    <HStack flexWrap="wrap">
                      {ingredientCategories.map((category) => (
                        <Pill
                          key={category}
                          selected={IngredientCategory[category] === value}
                          onPress={() => onChange(IngredientCategory[category])}
                        >
                          {IngredientCategory[category]}
                        </Pill>
                      ))}
                    </HStack>
                    {error && (
                      <FormControl.ErrorMessage testID="type-error">
                        {error.message}
                      </FormControl.ErrorMessage>
                    )}
                  </VStack>
                </FormControl>
              )}
            />
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button
              variant="outline"
              _text={{ _light: { color: 'black' }, _dark: { color: 'white' } }}
              onPress={onClose}
            >
              Cancelar
            </Button>
            <Button
              leftIcon={
                <Icon
                  as={MaterialIcons}
                  {...IconTheme.defaultProps}
                  name="save"
                  size="lg"
                />
              }
              onPress={handleSubmit(onSave)}
              isDisabled={!isValid || !isDirty}
            >
              Salvar ingrediente
            </Button>
          </Button.Group>
          {deleteButton}
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

const ingredientCategories = Object.keys(IngredientCategory) as Array<
  keyof typeof IngredientCategory
>;

const emptyDropdownIcon = <></>;
