import { getAppFromLocation } from '@sizeup/utils';
import foodco from './foodco';
import sizeup from './sizeup';

const themes = {
  foodco: foodco,
  sizeup: sizeup,
};

export function getTheme() {
  return themes[getAppFromLocation()];
}
