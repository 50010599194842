import React from 'react';

export const AuthTokenContext = React.createContext<string | null>(null);

export function AuthTokenProvider({
  token,
  children,
}: {
  token: string | null;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <AuthTokenContext.Provider value={token}>
      {children}
    </AuthTokenContext.Provider>
  );
}

export function useAuthToken() {
  const token = React.useContext(AuthTokenContext);
  return token;
}
