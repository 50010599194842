import { useCallback, useMemo } from 'react';
import { Box, useMediaQuery } from 'native-base';
import { useNavigation, StackActions } from '@react-navigation/native';

import { Header } from '@sizeup/components';
import { PageWithMenu } from '@sizeup/templates';

import { PurchasesList } from './components';
import { PurchaseItemForm, FormSchema } from './components/PurchaseItemForm';
import { useAddPurchase } from './hooks/useAddPurchase';
import { useAddPurchaseItems } from './hooks/useAddPurchaseItems';

export function PurchaseCreate() {
  const navigation = useNavigation();
  const [isLandscape] = useMediaQuery([{ orientation: 'landscape' }]);
  const defaultValues = useMemo(() => getDefaultValues(), []);
  const { createPurchase } = useAddPurchase();
  const { appendPurchaseItems } = useAddPurchaseItems();
  const savePurchase = useCallback(
    async (values: FormSchema) => {
      const result = await createPurchase({ supplierName: 'Nova Compra' });
      await appendPurchaseItems({
        purchaseId: result.purchaseId,
        items: [
          {
            name: values.name,
            quantity: values.quantity,
            dimension: values.dimension,
            unitCost: values.totalCost / values.quantity,
            brand: values.brand || undefined,
          },
        ],
      });
      return result;
    },
    [createPurchase, appendPurchaseItems]
  );
  const handleSaveAndAddAnother = useCallback(
    async (values: FormSchema) => {
      const createdPurchase = await savePurchase(values);
      navigation.dispatch(
        StackActions.replace('AddPurchaseItems', {
          id: createdPurchase.purchaseId,
        })
      );
    },
    [savePurchase, navigation]
  );
  const handleSaveAndReturn = useCallback(
    async (values: FormSchema) => {
      await savePurchase(values);
      navigation.navigate('Purchases');
    },
    [savePurchase, navigation]
  );
  return (
    <PageWithMenu selectedMenuOption="Purchases">
      {isLandscape && (
        <Box flex={1}>
          <Header title={'Compras'} />
          <PurchasesList />
        </Box>
      )}

      <Box flex={2} backgroundColor="white" shadow={10}>
        <Header title="Nova compra manual" subTitle="Adicionar Items" />
        <PurchaseItemForm
          onSaveAndReturn={handleSaveAndReturn}
          onSaveAndAddAnother={handleSaveAndAddAnother}
          defaultValues={defaultValues}
        />
      </Box>
    </PageWithMenu>
  );
}

function getDefaultValues() {
  return { dimension: 'g' };
}
