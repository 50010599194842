import { extendTheme } from 'native-base';
import { getTheme } from './getTheme';

const { theme } = getTheme();

const BaseTheme = extendTheme(theme);

type CustomThemeType = typeof BaseTheme;

declare module 'native-base' {
  type ICustomTheme = CustomThemeType;
}

export { theme, BaseTheme };
