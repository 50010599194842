import type { RouterOutput } from './utils/trpc';

export type ProductType = RouterOutput['product']['getList'][number];

export type IngredientType =
  RouterOutput['product']['getOne']['ingredients'][number];

export type SubproductType =
  RouterOutput['product']['getOne']['subproducts'][number];

export type PurchaseType = RouterOutput['purchase']['getList'][number];

export type PurchaseItemType =
  RouterOutput['purchase']['getOne']['items'][number];

export type IngredientNameType =
  RouterOutput['ingredientName']['getList'][number];

export type IngredientNameSuggestionType = NonNullable<
  RouterOutput['ingredientName']['getSuggestion']
>;

export enum IngredientCategory {
  Carnes = 'Carnes',
  Pescados = 'Pescados',
  Embutidos = 'Embutidos',
  TemperosCondimentos = 'Temperos e condimentos',
  OleosGorduras = 'Óleos e gorduras',
  FrutasLegumes = 'Frutas e legumes',
  Hortalicas = 'Hortaliças',
  Verduras = 'Verduras',
  Queijos = 'Queijos',
  Laticinios = 'Laticínios ',
  GraosCereais = 'Grãos e cereais',
  OleoaginosasSementes = 'Oleoaginosas e sementes',
  Farinhas = 'Farinhas',
  MassasPaes = 'Massas e pães',
  Quimicos = 'Químicos',
  OvosDerivados = 'Ovos e derivados',
  Bebidas = 'Bebidas',
  Molhos = 'Molhos',
}

export const DimensionOptions = [
  ['g', 'gramas'],
  ['kg', 'quilogramas'],
  ['ml', 'mililitros'],
  ['l', 'litros'],
  ['un', 'unidades'],
];
