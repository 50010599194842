import { Box, useMediaQuery } from 'native-base';
import { PageWithMenu } from '@sizeup/templates';
import { Header } from '@sizeup/components';
import { PurchasesList } from './components';

export function Purchases() {
  const [isLandscape] = useMediaQuery([{ orientation: 'landscape' }]);

  return (
    <PageWithMenu selectedMenuOption="Purchases">
      {/* left Panel */}
      <Box flex={1}>
        <Header title={'Compras'} />
        <PurchasesList />
      </Box>

      {/* right panel */}
      {isLandscape && <Box flex={2} backgroundColor="white" shadow={10} />}
    </PageWithMenu>
  );
}
