import React from 'react';
import { useAuth } from './useAuth';
import { AuthTokenProvider } from './AuthTokenProvider';
import { AuthForm } from './AuthForm';
import { LogoutProvider } from './LogoutProvider';
import { Loading } from './Loading';

export function Auth({ children }: { children: React.ReactNode }): JSX.Element {
  const {
    isAuthenticated,
    token,
    handleGoogleAuth,
    handleFoodCoAuth,
    handleLogout,
  } = useAuth();
  if (isAuthenticated === null) {
    return <Loading />;
  }
  if (!isAuthenticated) {
    return (
      <AuthForm
        onGoogleLoginPress={handleGoogleAuth}
        onFoodCoLoginPress={handleFoodCoAuth}
      />
    );
  }
  return (
    <AuthTokenProvider token={token}>
      <LogoutProvider onLogout={handleLogout}>{children}</LogoutProvider>
    </AuthTokenProvider>
  );
}
