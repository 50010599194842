import { Box, Heading, useMediaQuery } from 'native-base';

interface HeaderProps {
  title: string;
  subTitle?: string;
  goBack?: () => void;
}

export function Header({ title, subTitle }: HeaderProps) {
  // TODO: Header needs adjustments for portrait mode to be used
  const [isPortrait] = useMediaQuery([
    {
      orientation: 'portrait',
    },
  ]);

  if (isPortrait) return null;

  return (
    <Box
      px={7}
      h="100px"
      _light={{ bg: 'muted.50' }}
      _dark={{ bg: 'muted.900' }}
      alignItems="flex-start"
      justifyContent="center"
    >
      <Heading size="lg">{title}</Heading>
      {subTitle && <Heading size="md">{subTitle}</Heading>}
    </Box>
  );
}
