import { useCallback, useMemo, useState } from 'react';
import { trpc } from '../../../utils/trpc';
import type { ProductType, SubproductType } from '@sizeup/types';
import { SubproductForm } from './SubproductForm';
import { DeleteSubproduct } from './DeleteSubproduct';

interface Props {
  visible: boolean;
  productId: ProductType['productId'];
  subproduct: SubproductType;
  handleModalClose: () => void;
}

function SubproductModal({
  visible,
  productId,
  subproduct,
  handleModalClose,
}: Props) {
  const updateSubproduct = useUpdateSubproduct();
  const deleteSubproduct = useDeleteSubproduct();
  const handleSave: Parameters<typeof SubproductForm>[0]['onSave'] =
    useCallback(
      async (values) => {
        await updateSubproduct({
          productId: subproduct.productId,
          subproductId: subproduct.subproductId,
          quantity: values.quantity,
        });
        handleModalClose();
      },
      [updateSubproduct, subproduct, handleModalClose]
    );
  const handleDelete = useCallback(async () => {
    await deleteSubproduct({
      productId: subproduct.productId,
      subproductId: subproduct.subproductId,
    });
    handleModalClose();
  }, [deleteSubproduct, subproduct, handleModalClose]);
  return (
    <SubproductForm
      productId={productId}
      title={`Editar ${subproduct.name}`}
      visible={visible}
      defaultValues={subproduct}
      onSave={handleSave}
      onClose={handleModalClose}
      deleteButton={
        subproduct ? <DeleteSubproduct onClick={handleDelete} /> : null
      }
      subproductFieldDisabled
    />
  );
}

export function useSubproductModal(productId: ProductType['productId']) {
  const [visible, setVisible] = useState(false);
  const [subproduct, setSubproduct] = useState<SubproductType>();

  const showSubproduct = useCallback(
    (subproduct: SubproductType) => {
      setSubproduct(subproduct);
      setVisible(true);
    },
    [setSubproduct, setVisible]
  );

  const handleModalClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const ModalComp = useMemo(
    () =>
      subproduct && (
        <SubproductModal
          key={subproduct.name}
          visible={visible}
          productId={productId}
          subproduct={subproduct}
          handleModalClose={handleModalClose}
        />
      ),
    [visible, productId, subproduct, handleModalClose]
  );

  return { showSubproduct, SubproductModal: ModalComp };
}

export function useUpdateSubproduct() {
  const mutation = trpc.subproduct.update.useMutation();
  return mutation.mutateAsync;
}

export function useDeleteSubproduct() {
  const mutation = trpc.subproduct.delete.useMutation();
  return mutation.mutateAsync;
}
