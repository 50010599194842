import {
  Pressable,
  Center,
  HStack,
  Icon,
  Text,
  VStack,
  Box,
} from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

interface ListItemProps {
  text?: React.ReactNode | string;
  subText?: React.ReactNode | string;
  icon?: React.ReactNode | string;
  rightText?: React.ReactNode | string;
  rightSubText?: React.ReactNode | string;
  rightSubIcon?: React.ReactNode | string;
  isSelected?: boolean;
  showChevron?: boolean;
  backgroundColor?: string;
  scoreColor?: string;
  onPress?: () => void;
  testID?: string;
}

export function ListItem({
  text,
  subText,
  rightText,
  rightSubText,
  icon,
  rightSubIcon,
  isSelected = false,
  showChevron = false,
  backgroundColor = 'white',
  scoreColor,
  onPress,
  testID,
}: ListItemProps) {
  return (
    <Pressable
      onPress={onPress}
      _pressed={{ backgroundColor: 'muted.100' }}
      backgroundColor={isSelected ? 'muted.100' : backgroundColor}
      borderBottomWidth={1}
      borderColor="muted.100"
      testID={testID}
      flex={1}
    >
      {scoreColor && (
        <Box position="absolute" w={1} h="100%" backgroundColor={scoreColor} />
      )}
      <HStack space={1} py={3} px={7} flex={1}>
        {icon && (
          <Center pr={4}>
            {typeof icon === 'string' ? (
              <Icon as={MaterialIcons} name={icon} size={4} color="black" />
            ) : (
              icon
            )}
          </Center>
        )}
        <VStack flex={1} pr={7} justifyContent="center">
          {text && typeof text === 'string' ? (
            <Text fontSize="md">{text}</Text>
          ) : (
            text
          )}
          {subText && typeof subText === 'string' ? (
            <Text fontSize="xs" fontWeight="medium">
              {subText}
            </Text>
          ) : (
            subText
          )}
        </VStack>
        <VStack justifyContent="center" alignItems="flex-end">
          {rightText && typeof rightText === 'string' ? (
            <Text fontSize="sm" fontWeight="bold">
              {rightText}
            </Text>
          ) : (
            rightText
          )}
          <HStack alignItems="center" space={2}>
            {rightSubIcon && typeof rightSubIcon === 'string' ? (
              <Icon
                as={MaterialIcons}
                name={rightSubIcon}
                size={6}
                color="black"
              />
            ) : (
              rightSubIcon
            )}
            {rightSubText && typeof rightSubText === 'string' ? (
              <Text fontSize="xs" fontWeight="medium">
                {rightSubText}
              </Text>
            ) : (
              rightSubText
            )}
          </HStack>
        </VStack>
        {showChevron && (
          <Center pl={4}>
            <Icon
              as={MaterialIcons}
              name="chevron-right"
              size={6}
              color="muted.400"
            />
          </Center>
        )}
      </HStack>
    </Pressable>
  );
}
