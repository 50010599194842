import { Box, Center, Image, useTheme } from 'native-base';

import ArrowsSvg from '../assets/svgs/ArrowsSvg';

const imageSrc = {
  Products: require('../assets/heroImage.png'),
  Purchases: require('../assets/garlicHeroImage.png'),
};

interface EmptyStateFillerImageProps {
  image?: 'Products' | 'Purchases';
}

export function EmptyStateFillerImage({
  image = 'Products',
}: EmptyStateFillerImageProps) {
  const { colors } = useTheme();

  return (
    <Center flex={1} justifyContent="flex-end" overflow="hidden">
      <Box w={[480, 600]} h={[230, 400]} left={-30}>
        <ArrowsSvg
          left={{ stroke: colors.secondary[600] }}
          right={{ fill: undefined, stroke: colors.primary[600] }}
        />
      </Box>
      <Box position="absolute">
        <Image
          resizeMode="contain"
          source={imageSrc[image]}
          size={[322, 322]}
          alt="Empty Section"
        />
      </Box>
    </Center>
  );
}
