import { Box, HStack, Skeleton, VStack } from 'native-base';

export function ListLoadingSkeleton({
  numberOfItems = 3,
}: {
  numberOfItems?: number;
}) {
  return (
    <Box flex={1}>
      {[...Array(numberOfItems)].map((o, i) => (
        <HStack
          key={`${i}`}
          px={7}
          py={5}
          w="100%"
          borderBottomWidth="1"
          borderBottomColor="muted.100"
          space={1}
          justifyContent="space-between"
        >
          <VStack space={1} flex={2}>
            <Skeleton h={3} w="70%" rounded="full" />
            <Skeleton h={3} w="20%" rounded="full" />
          </VStack>
          <VStack
            space={1}
            flex={2}
            alignItems="flex-end"
            justifyContent="center"
          >
            <Skeleton h={3} w="30%" rounded="full" />
          </VStack>
        </HStack>
      ))}
    </Box>
  );
}
