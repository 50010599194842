import { useCallback, useEffect, useRef, useState } from 'react';
import { HStack, Modal, Text, VStack, Alert, Box } from 'native-base';

import type { PurchaseType, PurchaseItemType } from '@sizeup/types';
import { OptionsLoadingSkeleton } from '@sizeup/components';
import {
  useAllIngredientNames,
  IngredientConciliationStage,
} from '../hooks/useAllIngredientNames';
import { EvaluateSuggestion } from './EvaluateSuggestion';
import { PickIngredientForm } from './PickIngredientForm';

export function ConciliationModal({
  purchaseId,
  item,
  onClosed,
}: {
  purchaseId: PurchaseType['purchaseId'];
  item: PurchaseItemType;
  onClosed: () => void;
}) {
  const [visible, setVisible] = useState(true);

  const {
    stage,
    allIngredientNames,
    ingredientNameSuggestion,
    rejectSuggestion,
    confirmSuggestion,
    isSuccessful,
  } = useAllIngredientNames({ ...item, purchaseId });

  const onClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  useEffect(
    function closeOnSuccess() {
      if (isSuccessful) {
        onClose();
      }
    },
    [isSuccessful, onClose]
  );

  useEffect(() => {
    if (!visible) {
      onClosed();
    }
  }, [visible, onClosed]);

  const initialRef = useRef(null);

  return (
    <Modal
      accessibilityRole="none"
      accessibilityLabel={`Conciliação: ${item.name}`}
      accessibilityState={{ expanded: visible }}
      isOpen={visible}
      onClose={onClose}
      initialFocusRef={initialRef}
      size="lg"
    >
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>
          <Text>Conciliação: {item.name}</Text>
        </Modal.Header>
        {stage === IngredientConciliationStage.Loading && <Loading />}
        {stage === IngredientConciliationStage.Error && <Error />}
        {stage === IngredientConciliationStage.EvaluateSuggestion &&
          ingredientNameSuggestion && (
            <EvaluateSuggestion
              purchaseItem={item}
              ingredientNameSuggestion={ingredientNameSuggestion}
              onConfirm={confirmSuggestion}
              onReject={rejectSuggestion}
            />
          )}
        {stage === IngredientConciliationStage.PickIngredient &&
          allIngredientNames && (
            <PickIngredientForm
              purchaseItem={item}
              allIngredientNames={allIngredientNames}
              onSave={confirmSuggestion}
              onCancel={onClose}
            />
          )}
      </Modal.Content>
    </Modal>
  );
}

function Loading() {
  return (
    <Modal.Body accessibilityHint="loading">
      <OptionsLoadingSkeleton />
    </Modal.Body>
  );
}

function Error() {
  return (
    <Modal.Body justifyContent="center" alignItems="center">
      <Alert maxW="400" status="error" colorScheme="error">
        <VStack space={2} flexShrink={1} w="100%">
          <HStack
            flexShrink={1}
            space={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <HStack flexShrink={1} space={2} alignItems="center">
              <Alert.Icon />
              <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                Dados de conciliação não puderam ser carregados.
              </Text>
            </HStack>
          </HStack>
          <Box
            pl="6"
            _text={{
              color: 'coolGray.600',
            }}
          >
            Há algum problema com essa conciliação e ela não pôde ser exibida.
            Por favor, atualize a página e tente novamente. Se o problema
            persistir entre em contato com nosso suporte em foodco@sizeup.com
          </Box>
        </VStack>
      </Alert>
    </Modal.Body>
  );
}
