import { useState, useCallback } from 'react';
import { PillButton } from '@sizeup/components';
import { trpc } from '../../../utils/trpc';
import type { RouterInput } from '../../../utils/trpc';
import { IngredientForm } from './IngredientForm';

export function NewIngredientModalTrigger({
  productId,
}: Pick<RouterInput['ingredient']['create'], 'productId'>) {
  const [visible, setVisible] = useState(false);
  const { mutateAsync: createIngredient } =
    trpc.ingredient.create.useMutation();
  const handleSave = useCallback(
    async (
      values: Pick<
        RouterInput['ingredient']['create'],
        'name' | 'initialQuantity' | 'finalQuantity' | 'dimension' | 'type'
      >
    ) => {
      await createIngredient({
        productId,
        name: values.name,
        initialQuantity: values.initialQuantity || undefined,
        finalQuantity: values.finalQuantity || undefined,
        dimension: values.dimension || undefined,
        type: values.type || undefined,
      });
      setVisible(false);
    },
    [productId, createIngredient, setVisible]
  );
  return (
    <>
      <PillButton onPress={() => setVisible(true)}>
        + Novo Ingrediente
      </PillButton>
      <IngredientForm
        title="Novo Ingrediente"
        defaultValues={emptyDefaultValues}
        visible={visible}
        onSave={handleSave}
        onClose={() => setVisible(false)}
      />
    </>
  );
}

const emptyDefaultValues = {
  name: '',
  initialQuantity: null,
  finalQuantity: null,
  dimension: '',
  type: '',
};
