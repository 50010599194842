import { Button } from 'native-base';

interface Props {
  children: React.ReactElement | string;
  onPress?: () => void;
}

export function PillButton({ onPress, children }: Props) {
  const colorScheme = 'mutedLight';
  return (
    <Button
      size="xs"
      rounded="20"
      h={30}
      mb={2}
      mr={2}
      colorScheme={colorScheme}
      _text={{
        _light: { color: 'muted.900' },
        _dark: { color: 'muted.50' },
        fontSize: 'xs',
        fontWeight: 'bold',
      }}
      onPress={onPress}
      accessibilityRole="button"
    >
      {children}
    </Button>
  );
}
