export function toCurrency(value: number | string | undefined, decimals = 2) {
  return `R$ ${
    value
      ? Number(value).toLocaleString('pt-BR', {
          useGrouping: true,
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        })
      : '--'
  }`;
}

export function toPercentage(value: number | string | undefined, decimals = 2) {
  return `${
    value
      ? Number(value).toLocaleString('pt-BR', {
          useGrouping: true,
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        })
      : '--'
  } %`;
}
