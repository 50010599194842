import { useFonts } from 'expo-font';
import { NativeBaseProvider } from 'native-base';
import Constants from 'expo-constants';
import * as Sentry from 'sentry-expo';

import RootNavigation from './navigation';
import { BaseTheme } from './theme';
import { Auth } from './screens/Auth';
import { TRPCProvider } from './utils/TRPCProvider';

const sentryDsn = Constants?.expoConfig?.extra?.sentryDsn;

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    enableInExpoDevelopment: false,
    debug: false,
    tracesSampleRate: 1.0,
  });
}

export default function App() {
  const [fontsLoaded] = useFonts({
    'EncodeSans-Regular': require('./assets/fonts/EncodeSans-Regular.ttf'),
    'EncodeSans-Medium': require('./assets/fonts/EncodeSans-Medium.ttf'),
    'EncodeSans-Bold': require('./assets/fonts/EncodeSans-Bold.ttf'),
    'Baloo2-Bold': require('./assets/fonts/Baloo2-Bold.ttf'),
  });

  return (
    fontsLoaded && (
      <NativeBaseProvider theme={BaseTheme}>
        <Auth>
          <TRPCProvider>
            <RootNavigation />
          </TRPCProvider>
        </Auth>
      </NativeBaseProvider>
    )
  );
}
