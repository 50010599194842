import type { ComponentProps } from 'react';
import { Box, Image, Pressable, useBreakpointValue, Stack } from 'native-base';
import { useNavigation } from '@react-navigation/native';

import { LogoImage } from '@sizeup/theme/images';
import { BlockButton } from '../BlockButton';
import IconBarcodeScanSvg from '../../assets/svgs/IconBarcodeScanSvg';
import { MainStackNavigationType } from '../../navigation/types';

export function Menu({
  selectedOption,
}: {
  selectedOption: keyof MainStackNavigationType;
}) {
  const { navigate } = useNavigation();
  const menuLayout = useBreakpointValue({
    base: {
      h: '192px',
      w: '100%',
      justifyContent: 'center',
      borderTopRadius: 20,
    },
    sm: { h: '100%' },
  });
  const isLogoVisible = useBreakpointValue({
    base: false,
    sm: true,
  });

  return (
    <Box
      alignItems="center"
      _light={{ bg: 'muted.50' }}
      _dark={{ bg: 'muted.900' }}
      {...menuLayout}
    >
      {isLogoVisible && (
        <Pressable onPress={() => navigate('Home')}>
          <Image
            source={LogoImage}
            w="85px"
            h="100px"
            resizeMode="contain"
            alt="Logo"
          />
        </Pressable>
      )}
      <Stack
        direction={['row', 'column']}
        justifyContent="center"
        space={[0, 6]}
      >
        <MenuButton
          selected={isLogoVisible && selectedOption === 'Products'}
          title="Cardápio"
          icon="restaurant-menu"
          onPress={() => navigate('Products')}
        />
        <MenuButton
          selected={isLogoVisible && selectedOption === 'Purchases'}
          title="Compras"
          icon={<IconBarcodeScanSvg size={40} />}
          onPress={() => navigate('Purchases')}
        />
        <MenuButton
          selected={isLogoVisible && selectedOption === 'Profile'}
          title="Perfil"
          icon="person-outline"
          onPress={() => navigate('Profile')}
        />
      </Stack>
    </Box>
  );
}

const MenuButton = (
  props: Omit<ComponentProps<typeof BlockButton>, 'accessibilityRole'>
) => <BlockButton {...props} accessibilityRole="menuitem" />;
