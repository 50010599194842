import { useCallback } from 'react';
import { Button, Modal, Text } from 'native-base';
import type {
  PurchaseItemType,
  IngredientNameSuggestionType,
} from '@sizeup/types';
import type { RouterInput } from '../../../utils/trpc';

export function EvaluateSuggestion({
  purchaseItem,
  ingredientNameSuggestion,
  onConfirm,
  onReject,
}: {
  purchaseItem: PurchaseItemType;
  ingredientNameSuggestion: IngredientNameSuggestionType;
  onConfirm: (
    confirmedIngredientName: Pick<
      RouterInput['purchaseItem']['setIngredient'],
      'ingredientName' | 'dimension' | 'saveNameForFuturePurchases'
    >
  ) => void;
  onReject: () => void;
}) {
  const handleConfirmSuggestion = useCallback(() => {
    onConfirm({
      ingredientName: ingredientNameSuggestion.name,
      dimension: ingredientNameSuggestion.dimension,
      saveNameForFuturePurchases: true,
    });
  }, [onConfirm, ingredientNameSuggestion]);
  return (
    <>
      <Modal.Body>
        <Text testID="ingredient-name-suggestion">
          {`O item ${purchaseItem.name} (${purchaseItem.dimension}) corresponde ao ingrediente ${ingredientNameSuggestion.name} (${ingredientNameSuggestion.dimension})?`}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group space={2}>
          <Button onPress={onReject}>Não</Button>
          <Button onPress={handleConfirmSuggestion}>Sim</Button>
        </Button.Group>
      </Modal.Footer>
    </>
  );
}
