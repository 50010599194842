import { Box, useMediaQuery } from 'native-base';
import { PageWithMenu } from '@sizeup/templates';
import { Header } from '@sizeup/components';
import { ProductsList } from './components';

export function Products() {
  const [isLandscape] = useMediaQuery([{ orientation: 'landscape' }]);
  return (
    <PageWithMenu selectedMenuOption="Products">
      {/* left Panel */}
      <Box flex={1}>
        <Header title={'Cardápio'} />
        <ProductsList />
      </Box>

      {/* right panel */}
      {isLandscape && <Box flex={2} backgroundColor="white" shadow={10} />}
    </PageWithMenu>
  );
}
