import Constants from 'expo-constants';

export function getEnabledAuthProviders(currentHostname: string) {
  const allHostnamesProvidersNames: string =
    Constants?.expoConfig?.extra?.enabledAuthProviders ?? '';
  const allHostnamesProviders = allHostnamesProvidersNames.split(';');
  for (const item of allHostnamesProviders) {
    const [hostname, providersNames] = item.split(':');
    const providers = (providersNames ?? '').split(',');
    if (hostname === currentHostname) {
      return {
        ...Object.fromEntries(
          allProviders.map((provider) => [provider, false])
        ),
        ...Object.fromEntries(providers.map((provider) => [provider, true])),
      };
    }
  }
  return Object.fromEntries(allProviders.map((provider) => [provider, true]));
}

const allProviders = ['google', 'foodco'];
