import React from 'react';

type LogoutCallback = () => Promise<void>;

export const LogoutContext = React.createContext<LogoutCallback | null>(null);

export function LogoutProvider({
  onLogout,
  children,
}: {
  onLogout: LogoutCallback | null;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <LogoutContext.Provider value={onLogout}>{children}</LogoutContext.Provider>
  );
}

export function useLogout() {
  const token = React.useContext(LogoutContext);
  return token;
}
