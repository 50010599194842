import { useCallback } from 'react';
import { FlatList, VStack, HStack, Text } from 'native-base';

import { AlertIcon, ListItem, ListLoadingSkeleton } from '@sizeup/components';
import { IngredientType } from '@sizeup/types';
import { useIngredientsAndSubproducts } from '../hooks/useIngredientsAndSubproducts';
import { NewIngredientModalTrigger } from './NewIngredientModalTrigger';
import { useIngredientModal } from './IngredientModal';
import { NewSubproductModalTrigger } from './NewSubproductModalTrigger';
import { useSubproductModal } from './SubproductModal';

type Data = ReturnType<typeof useIngredientsAndSubproducts>['data'][number];

export function ProductIngredientsForm({
  productId,
}: Pick<IngredientType, 'productId'>) {
  const { data, loading, fetching } = useIngredientsAndSubproducts({
    productId,
  });
  const { showIngredient, IngredientModal } = useIngredientModal(productId);
  const { showSubproduct, SubproductModal } = useSubproductModal(productId);

  const onPressListItem = useCallback(
    (item: Data) => {
      const { instance } = item;
      if ('subproductId' in instance) {
        showSubproduct(instance);
      } else {
        showIngredient(instance);
      }
    },
    [showIngredient, showSubproduct]
  );

  const renderIngredient = useCallback(
    ({ item }: { item: Data }) => (
      <ListItem
        testID={`${item.type}-item-${item.id}`}
        text={item.name}
        showChevron={!item.errors}
        rightText={item.totalCost}
        rightSubText={item.quantity}
        rightSubIcon={item.errors ? <AlertIcon /> : null}
        onPress={() => onPressListItem(item)}
      />
    ),
    [onPressListItem]
  );

  const keyExtractor = useCallback((item: Data) => item.name, []);

  if (loading) {
    return <ListLoadingSkeleton />;
  }

  return (
    <>
      <VStack flex={1}>
        <Text fontWeight="bold" mt={4}>
          Ingredientes
        </Text>
        <HStack space={2} pb={2} pt={4}>
          <NewIngredientModalTrigger productId={productId} />
          <NewSubproductModalTrigger productId={productId} />
        </HStack>
        <FlatList
          flex={1}
          data={data}
          refreshing={fetching}
          keyExtractor={keyExtractor}
          renderItem={renderIngredient}
        />
      </VStack>
      {IngredientModal}
      {SubproductModal}
    </>
  );
}
