import { HStack, Skeleton, VStack } from 'native-base';

export function OptionsLoadingSkeleton() {
  return (
    <VStack space={6}>
      <VStack space={1} flex={2}>
        <Skeleton h={3} w="40%" rounded="full" />
        <Skeleton h={3} w="90%" rounded="full" />
      </VStack>
      <VStack space={1} flex={2}>
        <Skeleton h={3} w="20%" rounded="full" />
        <HStack justifyContent="space-between">
          <Skeleton h={3} w="70%" rounded="full" />
          <Skeleton h={3} w="20%" rounded="full" />
        </HStack>
      </VStack>
      <VStack space={1} flex={2}>
        <Skeleton h={3} w="40%" rounded="full" />
        <Skeleton h={3} w="90%" rounded="full" />
      </VStack>
    </VStack>
  );
}
