import { Box, useMediaQuery } from 'native-base';

import { Header } from '@sizeup/components';
import { PageWithMenu } from '@sizeup/templates';
import { MainStackNavigationProps } from '@sizeup/navigation';

import {
  ProductsList,
  ProductForm,
  EmptyIngredientsFiller,
} from './components';
import { useCallback } from 'react';
import { trpc } from '../../utils/trpc';

export function ProductCreate({
  navigation: { navigate },
}: MainStackNavigationProps<'ProductCreate'>) {
  const [isLandscape] = useMediaQuery([{ orientation: 'landscape' }]);
  const createProduct = useCreateProduct();
  const handleSave = useCallback(
    async (
      values: Omit<Parameters<typeof createProduct>[0], 'listingLevel'>
    ) => {
      const data = await createProduct({
        name: values.name,
        listingLevel: 'primary',
        category: values.category,
        salesPrice: values.salesPrice,
        numberOfServings: values.numberOfServings,
        preparationTimeInMinutes: values.preparationTimeInMinutes,
        recipeYield: values.recipeYield,
        recipeYieldDimension: values.recipeYieldDimension,
        recipeDirections: values.recipeDirections,
      });
      const productId = data.productId;
      navigate('ProductUpdate', { id: productId });
    },
    [createProduct, navigate]
  );

  return (
    <PageWithMenu selectedMenuOption="Products">
      {isLandscape && (
        <Box flex={1}>
          <Header title={'Cardápio'} />
          <ProductsList />
        </Box>
      )}

      <Box flex={2} backgroundColor="white" shadow={10}>
        <Header title={'Nova Receita'} />
        <ProductForm defaultValues={emptyDefaultValues} onSave={handleSave}>
          <EmptyIngredientsFiller />
        </ProductForm>
      </Box>
    </PageWithMenu>
  );
}

const emptyDefaultValues = Object.freeze({
  category: '',
  name: '',
  salesPrice: null,
  numberOfServings: null,
  preparationTimeInMinutes: null,
  recipeYield: null,
  recipeYieldDimension: 'g',
  recipeDirections: '',
});

export function useCreateProduct() {
  const mutation = trpc.product.create.useMutation();
  return mutation.mutateAsync;
}
