import { useState, useCallback } from 'react';
import { PillButton } from '@sizeup/components';
import { trpc } from '../../../utils/trpc';
import type { RouterInput } from '../../../utils/trpc';
import { SubproductForm } from './SubproductForm';

export function NewSubproductModalTrigger({
  productId,
}: Pick<RouterInput['subproduct']['create'], 'productId'>) {
  const [visible, setVisible] = useState(false);
  const { mutateAsync: createSubproduct } =
    trpc.subproduct.create.useMutation();
  const handleSave = useCallback(
    async (
      values: Pick<
        RouterInput['subproduct']['create'],
        'subproductId' | 'quantity'
      >
    ) => {
      await createSubproduct({ productId, ...values });
      setVisible(false);
    },
    [productId, createSubproduct, setVisible]
  );
  return (
    <>
      <PillButton onPress={() => setVisible(true)}>
        + Incluir Subproduto
      </PillButton>
      <SubproductForm
        productId={productId}
        title="Incluir Subproduto"
        defaultValues={emptyDefaultValues}
        visible={visible}
        onSave={handleSave}
        onClose={() => setVisible(false)}
      />
    </>
  );
}

const emptyDefaultValues = { subproductId: '', quantity: '' };
