import { useState, useCallback } from 'react';
import { trpc } from '../../../utils/trpc';
import type { PurchaseItemType, IngredientNameType } from '@sizeup/types';

export enum IngredientConciliationStage {
  Loading = 'Loading',
  Error = 'Error',
  EvaluateSuggestion = 'EvaluateSuggestion',
  PickIngredient = 'PickIngredient',
}

export function useAllIngredientNames({
  purchaseId,
  purchaseItemId,
  name,
  dimension,
}: Pick<PurchaseItemType, 'purchaseId' | 'purchaseItemId'> &
  Pick<IngredientNameType, 'name' | 'dimension'>) {
  const [suggestionWasRejected, setSuggestionWasRejected] = useState(false);
  const [ingredientNameSaved, setIngredientNameSaved] = useState(false);
  const { data: allIngredientNames, error: allIngredientNamesError } =
    trpc.ingredientName.getList.useQuery();
  const {
    data: ingredientNameSuggestion,
    error: ingredientNameSuggestionError,
  } = trpc.ingredientName.getSuggestion.useQuery({ name, dimension });
  const { mutateAsync: setPurchaseItemIngredient } =
    trpc.purchaseItem.setIngredient.useMutation();

  const rejectSuggestion = useCallback(() => {
    setSuggestionWasRejected(true);
  }, [setSuggestionWasRejected]);

  const confirmSuggestion = useCallback(
    async ({
      ingredientName,
      dimension,
      proportion,
      saveNameForFuturePurchases,
    }: Pick<
      Parameters<typeof setPurchaseItemIngredient>[0],
      | 'ingredientName'
      | 'dimension'
      | 'proportion'
      | 'saveNameForFuturePurchases'
    >) => {
      await setPurchaseItemIngredient({
        purchaseId,
        purchaseItemId,
        ingredientName,
        dimension,
        proportion,
        saveNameForFuturePurchases,
      });
      setIngredientNameSaved(true);
    },
    [
      purchaseId,
      purchaseItemId,
      setPurchaseItemIngredient,
      setIngredientNameSaved,
    ]
  );

  let stage: IngredientConciliationStage;
  if (allIngredientNamesError || ingredientNameSuggestionError) {
    stage = IngredientConciliationStage.Error;
  } else if (
    allIngredientNames === undefined ||
    ingredientNameSuggestion === undefined
  ) {
    stage = IngredientConciliationStage.Loading;
  } else if (ingredientNameSuggestion && !suggestionWasRejected) {
    stage = IngredientConciliationStage.EvaluateSuggestion;
  } else {
    stage = IngredientConciliationStage.PickIngredient;
  }

  return {
    stage,
    allIngredientNames,
    ingredientNameSuggestion,
    confirmSuggestion,
    rejectSuggestion,
    isSuccessful: ingredientNameSaved,
  };
}
