import { ColorValue } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';
interface IconSvgProps extends SvgProps {
  color?: ColorValue;
  size?: number;
}

const IconBarcodeScanSvg = ({
  color = '#000',
  size = 24,
  ...rest
}: IconSvgProps) => (
  <Svg width={size} height={size} viewBox={'0 0 24 24'} fill="none" {...rest}>
    <Path
      d="M6 6h2v12H6V6Zm3 0h1v12H9V6Zm3 0h3v12h-3V6Zm4 0h2v12h-2V6ZM4 4v4H2V4a2 2 0 0 1 2-2h4v2H4Zm16-2a2 2 0 0 1 2 2v4h-2V4h-4V2h4ZM4 16v4h4v2H4a2 2 0 0 1-2-2v-4h2Zm16 4v-4h2v4a2 2 0 0 1-2 2h-4v-2h4Z"
      fill={color}
    />
  </Svg>
);

export default IconBarcodeScanSvg;
