export function getAppFromLocation() {
  let hostname: string;
  try {
    ({ hostname } = location);
  } catch (exception) {
    if (exception instanceof ReferenceError) {
      hostname = '';
    } else {
      throw exception;
    }
  }
  if (hostname.search(/foodco/i) !== -1) {
    return 'foodco';
  }
  return 'sizeup';
}
