import { useCallback, useMemo, useState } from 'react';
import { trpc } from '../../../utils/trpc';
import type { ProductType, IngredientType } from '@sizeup/types';
import { IngredientForm } from './IngredientForm';
// import {DeleteIngredient} from './DeleteIngredient';

interface Props {
  visible: boolean;
  productId: ProductType['productId'];
  ingredient: IngredientType;
  handleModalClose: () => void;
}

function IngredientModal({
  visible,
  productId,
  ingredient,
  handleModalClose,
}: Props) {
  const updateIngredient = useUpdateIngredient();
  //const deleteIngredient = useDeleteIngredient();
  const handleSave: Parameters<typeof IngredientForm>[0]['onSave'] =
    useCallback(
      async (values) => {
        await updateIngredient({
          productId: productId,
          name: ingredient.name,
          initialQuantity: values.initialQuantity || undefined,
          finalQuantity: values.finalQuantity || undefined,
          dimension: values.dimension || undefined,
          type: values.type || undefined,
        });
        handleModalClose();
      },
      [updateIngredient, productId, ingredient, handleModalClose]
    );
  /*
  const handleDelete = useCallback(async () => {
    await deleteIngredient({
      productId: ingredient.productId,
      ingredientId: ingredient.ingredientId,
    });
    handleModalClose();
  }, [deleteIngredient, ingredient, handleModalClose]);
  */
  return (
    <IngredientForm
      title={`Editar ${ingredient.name}`}
      visible={visible}
      defaultValues={{
        ...ingredient,
        type: ingredient.type ?? '',
        dimension: ingredient.dimension ?? '',
      }}
      onSave={handleSave}
      onClose={handleModalClose}
      nameFieldDisabled
    />
  );
}

/*
deleteButton={
  ingredient ? <DeleteIngredient onClick={handleDelete} /> : null
}
*/

export function useIngredientModal(productId: ProductType['productId']) {
  const [visible, setVisible] = useState(false);
  const [ingredient, setIngredient] = useState<IngredientType>();

  const showIngredient = useCallback(
    (ingredient: IngredientType) => {
      setIngredient(ingredient);
      setVisible(true);
    },
    [setIngredient, setVisible]
  );

  const handleModalClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const ModalComp = useMemo(
    () =>
      ingredient && (
        <IngredientModal
          key={ingredient.name}
          visible={visible}
          productId={productId}
          ingredient={ingredient}
          handleModalClose={handleModalClose}
        />
      ),
    [visible, productId, ingredient, handleModalClose]
  );

  return { showIngredient, IngredientModal: ModalComp };
}

export function useUpdateIngredient() {
  const mutation = trpc.ingredient.update.useMutation();
  return mutation.mutateAsync;
}

export function useDeleteIngredient() {
  const mutation = trpc.ingredient.delete.useMutation();
  return mutation.mutateAsync;
}
