import type { ReactNode } from 'react';
import { useCallback } from 'react';
import {
  ScrollView,
  Input,
  FormControl,
  HStack,
  VStack,
  Box,
  TextArea,
  Select,
  Stack,
  useMediaQuery,
} from 'native-base';
import { useForm, Controller } from 'react-hook-form';
import { useFocusEffect } from '@react-navigation/native';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { DimensionOptions } from '@sizeup/types';
import { ProductFormActions } from '.';
import { CurrencyInput, NumberInput } from '@sizeup/components';
import * as formUtils from '../../../utils/forms';

export const validationSchema = z.object({
  name: formUtils.notEmptyString(),
  category: z.string(),
  numberOfServings: formUtils.stringAsNumber().nullable(),
  preparationTimeInMinutes: formUtils.stringAsNumber().nullable(),
  recipeYield: formUtils.stringAsNumber().nullable(),
  recipeYieldDimension: z.string(),
  salesPrice: formUtils.stringAsNumber().nullable(),
  recipeDirections: z.string(),
});

type InputSchema = z.input<typeof validationSchema>;
type FormSchema = z.output<typeof validationSchema>;

interface ProductFormProps {
  defaultValues: FormSchema;
  onSave: (values: FormSchema) => void;
  header?: ReactNode;
  children: ReactNode;
  disabled?: boolean;
}

export function ProductForm({
  defaultValues,
  onSave,
  header = null,
  children,
  disabled = false,
}: ProductFormProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<InputSchema, unknown, FormSchema>({
    resolver: zodResolver(validationSchema),
    mode: 'onBlur',
  });
  useFocusEffect(
    useCallback(() => {
      if (defaultValues) {
        reset(defaultValues);
      }
    }, [defaultValues, reset])
  );

  const [isPortrait] = useMediaQuery([{ orientation: 'portrait' }]);

  return (
    <Box flex={1}>
      <FormControl flex={1}>
        <ScrollView>
          {header}
          <Stack direction={isPortrait ? 'column' : 'row'} flex={1}>
            <Box flex={isPortrait ? undefined : 1} px={7} pb={7}>
              <FormControl.Label my={4}>Nome do produto</FormControl.Label>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <FormControl isInvalid={Boolean(error)}>
                    <VStack>
                      <Input
                        placeholder="Ex.: File à parmegiana"
                        accessibilityLabel="Nome do produto"
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        isDisabled={disabled}
                      />
                      {error && (
                        <FormControl.ErrorMessage>
                          {error.message}
                        </FormControl.ErrorMessage>
                      )}
                    </VStack>
                  </FormControl>
                )}
              />
              <FormControl.Label my={4}>Categoria</FormControl.Label>
              <Controller
                name="category"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <FormControl isInvalid={Boolean(error)}>
                    <VStack>
                      <Input
                        accessibilityLabel="Categoria"
                        placeholder="Ex.: Prato principal"
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value ?? undefined}
                        isDisabled={disabled}
                      />
                      {error && (
                        <FormControl.ErrorMessage>
                          {error.message}
                        </FormControl.ErrorMessage>
                      )}
                    </VStack>
                  </FormControl>
                )}
              />
              <FormControl.Label my={4}>Preço de venda</FormControl.Label>
              <Controller
                name="salesPrice"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <FormControl isInvalid={Boolean(error)}>
                    <VStack>
                      <CurrencyInput
                        accessibilityLabel="Preço de venda"
                        placeholder="Ex.: R$ 76,50"
                        value={value}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        isDisabled={disabled}
                      />
                      {error?.message && (
                        <FormControl.ErrorMessage>
                          {error.message}
                        </FormControl.ErrorMessage>
                      )}
                    </VStack>
                  </FormControl>
                )}
              />
              <FormControl.Label mt={4}>
                Rendimento da receita
              </FormControl.Label>
              <FormControl.HelperText mb={4}>
                Quantidade em porções e peso por porção que esta receita produz
              </FormControl.HelperText>
              <HStack space={1} mb={4}>
                <Controller
                  name="numberOfServings"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <VStack flex={3}>
                      <FormControl isInvalid={Boolean(error)}>
                        <NumberInput
                          accessibilityLabel="Porções"
                          placeholder="Ex.: 2"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                          isDisabled={disabled}
                        />
                        {error && (
                          <FormControl.ErrorMessage>
                            {error.message}
                          </FormControl.ErrorMessage>
                        )}
                      </FormControl>
                    </VStack>
                  )}
                />
                <Input
                  flex={1}
                  editable={false}
                  variant="unstyled"
                  value="porções"
                />
              </HStack>
              <HStack space={1}>
                <Controller
                  name="recipeYield"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <VStack flex={3}>
                      <FormControl isInvalid={Boolean(error)}>
                        <NumberInput
                          accessibilityLabel="Rendimento"
                          flex={3}
                          placeholder="Ex.: 350"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                          isDisabled={disabled}
                        />
                        {error && (
                          <FormControl.ErrorMessage>
                            {error.message}
                          </FormControl.ErrorMessage>
                        )}
                      </FormControl>
                    </VStack>
                  )}
                />
                <Controller
                  name="recipeYieldDimension"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <VStack flex={1}>
                      <FormControl isInvalid={Boolean(error)}>
                        <Select
                          accessibilityLabel="Unidade do rendimento"
                          onValueChange={onChange}
                          selectedValue={value ?? undefined}
                          placeholder="Escolha uma opção"
                          dropdownIcon={<></>}
                          isDisabled={disabled}
                        >
                          <Select.Item label={'Nenhuma dimensão'} value={''} />
                          {DimensionOptions.map(([dimension, label]) => (
                            <Select.Item
                              key={dimension}
                              label={label}
                              value={dimension}
                            />
                          ))}
                        </Select>
                        {error && (
                          <FormControl.ErrorMessage>
                            {error.message}
                          </FormControl.ErrorMessage>
                        )}
                      </FormControl>
                    </VStack>
                  )}
                />
              </HStack>

              <FormControl.Label my={4}>Tempo de preparo</FormControl.Label>
              <HStack space={1}>
                <Controller
                  name="preparationTimeInMinutes"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <VStack flex={3}>
                      <FormControl isInvalid={Boolean(error)}>
                        <NumberInput
                          accessibilityLabel="Tempo de preparo"
                          size="md"
                          placeholder="Ex.: 20"
                          onChangeText={onChange}
                          onBlur={onBlur}
                          value={value}
                          isDisabled={disabled}
                        />
                        {error && (
                          <FormControl.ErrorMessage>
                            {error.message}
                          </FormControl.ErrorMessage>
                        )}
                      </FormControl>
                    </VStack>
                  )}
                />
                <Input
                  flex={1}
                  editable={false}
                  variant="unstyled"
                  value="minutos"
                />
              </HStack>
              <FormControl.Label my={4}>Modo de preparo</FormControl.Label>
              <Controller
                name="recipeDirections"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <FormControl isInvalid={Boolean(error)}>
                    <TextArea
                      accessibilityLabel="Modo de preparo"
                      autoCompleteType={true}
                      placeholder={recipeDirectionsPlaceholder}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      value={value}
                      isDisabled={disabled}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box flex={isPortrait ? undefined : 1} px={7} pb={7}>
              {children}
            </Box>
          </Stack>
        </ScrollView>
      </FormControl>

      <ProductFormActions
        isDisabled={!isDirty || !isValid}
        handleSave={handleSubmit(onSave)}
      />
    </Box>
  );
}

const recipeDirectionsPlaceholder = `* Toalete peça mignon, retirando cordão${'\n'}* Fatiar mignon em filet de 3 cm de altura${'\n'}* Grelhar filet em frigideira de aço inox bem${'\n'}quente, 2 minutos cada lado${'\n'}...`;
