import { Icon } from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';

export const AlertIcon = () => (
  <Icon
    as={MaterialIcons}
    name="warning"
    size={6}
    color="warning.600"
    accessibilityRole="alert"
  />
);
