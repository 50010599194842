import { Fragment, useCallback, useMemo } from 'react';
import { FlatList } from 'native-base';
import { useNavigation } from '@react-navigation/native';

import {
  AlertIcon,
  ListItem,
  ListLoadingSkeleton,
  EmptyStateFillerImage,
} from '@sizeup/components';
import { toCurrency } from '@sizeup/utils';

import { PurchasesActions } from '.';
import { usePurchases } from '../hooks';

interface PurchasesListProps {
  selectedPurchaseId?: string;
}

export function PurchasesList({ selectedPurchaseId }: PurchasesListProps) {
  const { navigate } = useNavigation();
  const { purchases, fetching, empty, loading } = usePurchases();

  const renderPurchaseItem = useCallback(
    ({ item }: { item: NonNullable<typeof purchases>[number] }) => (
      <ListItem
        key={item.purchaseId}
        text={item.supplierName || 'Compra'}
        subText={item.createdAt?.toLocaleDateString()}
        rightText={toCurrency(item.totalCost)}
        rightSubIcon={item.hasErrors ? <AlertIcon /> : undefined}
        isSelected={
          !!selectedPurchaseId && selectedPurchaseId === item.purchaseId
        }
        onPress={() => navigate('PurchaseDetail', { id: item.purchaseId })}
        showChevron
        testID={`purchase-${item.purchaseId}`}
      />
    ),
    [navigate, selectedPurchaseId]
  );

  const keyExtractor = useCallback(
    (item: NonNullable<typeof purchases>[number]) => item.purchaseId,
    []
  );

  const renderList = useMemo(() => {
    if (loading) {
      return <ListLoadingSkeleton />;
    }

    if (empty) {
      return <EmptyStateFillerImage image="Purchases" />;
    }

    return (
      <FlatList
        flex={1}
        refreshing={fetching}
        data={purchases}
        renderItem={renderPurchaseItem}
        keyExtractor={keyExtractor}
      />
    );
  }, [loading, empty, fetching, purchases, renderPurchaseItem, keyExtractor]);

  return (
    <Fragment>
      {renderList}
      <PurchasesActions
        isEmptyState={empty}
        handleAddManualPurchaseOnPress={() => navigate('PurchaseCreate')}
      />
    </Fragment>
  );
}
