const theme = {
  config: {
    initialColorMode: 'light',
  },

  colors: {
    primary: {
      50: '#c5ccf2',
      100: '#96a1df',
      200: '#6d7ac5',
      300: '#505c9f',
      400: '#505c9f',
      500: '#3B4474',
      600: '#000A41',
      700: '#000A41',
      800: '#000A41',
      900: '#11111F',
    },
    secondary: {
      50: '#fffefa',
      100: '#fff4cc',
      200: '#ffea9e',
      300: '#ffe070',
      400: '#ffd642',
      500: '#ffdc42',
      600: '#ffcc15',
      700: '#cea50f',
      800: '#907617',
      900: '#241602',
    },
    accent: {
      600: '#075985',
      700: '#0369a1',
      800: '#0c4a6e',
      menuBg: '#000A41',
    },
    mutedLight: {
      600: '#e5e5e5',
      700: '#d4d4d4',
      800: '#a3a3a3',
      900: '#737373',
    },
    light: {
      50: '#ffffff',
    },
  },

  fontConfig: {
    Encode: {
      // normal
      400: {
        normal: 'EncodeSans-Regular',
      },
      // medium
      500: {
        normal: 'EncodeSans-Medium',
      },
      // bold
      700: {
        normal: 'EncodeSans-Bold',
      },
    },
    Baloo2: {
      700: {
        normal: 'Baloo2-Bold',
      },
    },
  },

  fonts: {
    heading: 'Baloo2',
    body: 'Encode',
  },

  shadows: {
    '10': {
      'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)',
    },
  },

  components: {
    Text: {
      defaultProps: {
        fontSize: 'md',
        fontFamily: 'body',
        fontWeight: '400',
        fontStyle: 'normal',
        _light: { color: 'text.900' },
        _dark: { color: 'text.50' },
      },
    },
    Heading: {
      defaultProps: {
        size: 'md',
        fontFamily: 'heading',
        fontWeight: '700',
        fontStyle: 'normal',
        _light: { color: 'text.900' },
        _dark: { color: 'text.50' },
      },
    },
    Button: {
      defaultProps: {
        colorScheme: 'secondary',
        size: 'md',
        _text: {
          _light: { color: 'text.900' },
          _dark: { color: 'text.900' },
          fontSize: 'md',
          fontWeight: 'medium',
          fontFamily: 'body',
        },
      },
    },
    Icon: {
      defaultProps: {
        color: 'text.900',
      },
    },
    FormControlLabel: {
      defaultProps: {
        _text: {
          _light: { color: 'text.900' },
          _dark: { color: 'text.50' },
          fontWeight: 'bold',
          fontSize: 'md',
          fontFamily: 'body',
        },
      },
    },
    Select: {
      defaultProps: {
        size: 'lg',
      },
    },
    Input: {
      defaultProps: {
        size: 'lg',
      },
    },
  },
};

const LogoImage = require('../assets/foodco/foodcoLogo.png'); // eslint-disable-line @typescript-eslint/no-var-requires
const LogoImageAuth = require('../assets/foodco/foodcoLogoWhite.png'); // eslint-disable-line @typescript-eslint/no-var-requires

export default { theme, LogoImage, LogoImageAuth };
