import { useCallback, useMemo } from 'react';
import { Box, useMediaQuery } from 'native-base';
import { useNavigation } from '@react-navigation/native';

import { Header } from '@sizeup/components';
import { PageWithMenu } from '@sizeup/templates';
import { MainStackNavigationProps } from '@sizeup/navigation';

import { PurchasesList } from './components/PurchasesList';
import { PurchaseItemForm, FormSchema } from './components/PurchaseItemForm';
import { usePurchaseDetail, useAddPurchaseItems } from './hooks';

export function AddPurchaseItems({
  route,
}: MainStackNavigationProps<'AddPurchaseItems'>) {
  const { params } = route;
  const { navigate } = useNavigation();
  const { purchase } = usePurchaseDetail({ purchaseId: params.id });
  const [isLandscape] = useMediaQuery([{ orientation: 'landscape' }]);
  const defaultValues = useMemo(() => getDefaultValues(), []);
  const { appendPurchaseItems } = useAddPurchaseItems();
  const savePurchaseItem = useCallback(
    async (values: FormSchema) => {
      if (!purchase) {
        throw new Error('Purchase not found');
      }
      await appendPurchaseItems({
        purchaseId: purchase.purchaseId,
        items: [
          {
            name: values.name,
            quantity: values.quantity,
            dimension: values.dimension,
            unitCost: values.totalCost / values.quantity,
            brand: values.brand || undefined,
          },
        ],
      });
    },
    [purchase, appendPurchaseItems]
  );
  const handleSaveAndAddAnother = useCallback(
    async (values: FormSchema) => {
      await savePurchaseItem(values);
    },
    [savePurchaseItem]
  );
  const handleSaveAndReturn = useCallback(
    async (values: FormSchema) => {
      await savePurchaseItem(values);
      navigate('Purchases');
    },
    [savePurchaseItem, navigate]
  );
  return (
    <PageWithMenu selectedMenuOption="Purchases">
      {isLandscape && (
        <Box flex={1}>
          <Header title={'Compras'} />
          <PurchasesList selectedPurchaseId={purchase?.purchaseId} />
        </Box>
      )}
      <Box flex={2} backgroundColor="white" shadow={10}>
        <Header
          title={purchase?.supplierName || 'Nova compra manual'}
          subTitle={'Adicionar Items'}
        />
        {purchase?.purchaseId && (
          <PurchaseItemForm
            onSaveAndReturn={handleSaveAndReturn}
            onSaveAndAddAnother={handleSaveAndAddAnother}
            defaultValues={defaultValues}
          />
        )}
      </Box>
    </PageWithMenu>
  );
}

function getDefaultValues() {
  return { dimension: 'gramas' };
}
