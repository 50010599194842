import { useState } from 'react';
import Constants from 'expo-constants';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import superjson from 'superjson';
import { trpc } from './trpc';
import { useAuthToken } from '../screens/Auth';

export function TRPCProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const token = useAuthToken();
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: Constants?.expoConfig?.extra?.trpcApiUrl,
          headers: {
            authorization: `Bearer ${token}`,
          },
        }),
      ],
    })
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
