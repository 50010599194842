import { Platform } from 'react-native';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Box, Pressable, Icon, useMediaQuery } from 'native-base';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { MainStackNavigationType } from './types';

import { Home } from '../screens/Home';
import { Profile } from '../screens/Profile';
import {
  Purchases,
  PurchaseCreate,
  PurchaseDetail,
  AddPurchaseItems,
} from '../screens/Purchases';
import { Products, ProductCreate, ProductUpdate } from '../screens/Products';

import linking from './linking';

const Stack = createNativeStackNavigator<MainStackNavigationType>();

function CustomBackButton() {
  const { goBack } = useNavigation();
  return (
    <Pressable pl={3} onPress={goBack}>
      {({ isPressed }) => {
        return (
          <Icon
            as={MaterialIcons}
            name={'arrow-back'}
            size={7}
            color={isPressed ? 'muted.300' : 'muted:600'}
          />
        );
      }}
    </Pressable>
  );
}

export default function RootNavigation() {
  const [isPortrait] = useMediaQuery([
    {
      orientation: 'portrait',
    },
  ]);

  return (
    <NavigationContainer linking={linking}>
      <Box flex={1}>
        <Stack.Navigator
          initialRouteName="Home"
          screenOptions={{
            headerBackTitleVisible: false,
            headerTintColor: 'black',
            headerShown: isPortrait,
            headerLeft:
              Platform.OS !== 'web' ? () => <CustomBackButton /> : undefined,
          }}
        >
          <Stack.Screen
            name="Home"
            component={Home}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="Profile"
            component={Profile}
            options={{
              title: 'Perfil',
            }}
          />
          <Stack.Screen
            name="Purchases"
            component={Purchases}
            options={{
              title: 'Compras',
            }}
          />
          <Stack.Screen
            name="PurchaseCreate"
            component={PurchaseCreate}
            options={{
              title: 'Nova compra manual',
            }}
          />
          <Stack.Screen
            name="PurchaseDetail"
            component={PurchaseDetail}
            options={{
              title: 'Compra',
            }}
          />
          <Stack.Screen
            name="AddPurchaseItems"
            component={AddPurchaseItems}
            options={{
              title: 'Nova compra manual',
            }}
          />
          <Stack.Screen
            name="Products"
            component={Products}
            options={{ title: 'Cardápio' }}
          />
          <Stack.Screen
            name="ProductCreate"
            component={ProductCreate}
            options={{ title: 'Novo produto' }}
          />
          <Stack.Screen
            name="ProductUpdate"
            component={ProductUpdate}
            options={{ title: 'Atualizar produto' }}
          />
        </Stack.Navigator>
      </Box>
    </NavigationContainer>
  );
}
