import { useReducer, useCallback, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';

const googleAuthUrl = Constants?.expoConfig?.extra?.googleAuthUrl;
const foodCoAuthUrl = Constants?.expoConfig?.extra?.foodCoAuthUrl;
const tokenStorageName = '@sizeup-auth-token';

WebBrowser.maybeCompleteAuthSession();

export function useAuth() {
  const [state, dispatch] = useReducer(authReducer, authInitialState);

  useEffect(
    function loadTokenFromStorage() {
      async function apply() {
        try {
          const token = await AsyncStorage.getItem(tokenStorageName);
          if (token !== null) {
            dispatch({ type: 'authenticated', token });
            return;
          }
        } catch (e) {
          console.log('Error loading from storage');
        }
        dispatch({ type: 'notAuthenticated' });
      }
      apply();
    },
    [dispatch]
  );

  const handleAuth = useCallback(
    async (authUrl: string) => {
      const result = await WebBrowser.openAuthSessionAsync(
        authUrl + `?redirect=${location.origin}`,
        Linking.createURL('/')
      );
      if (result.type === 'success') {
        const parsedUrl = Linking.parse(result.url);
        const token = parsedUrl.queryParams?.token;
        if (typeof token !== 'string') {
          throw new Error('Missing token query parameter');
        }
        await AsyncStorage.setItem(tokenStorageName, token);
        dispatch({ type: 'authenticated', token });
      }
    },
    [dispatch]
  );

  const handleGoogleAuth = useCallback(async () => {
    await handleAuth(googleAuthUrl);
  }, [handleAuth]);

  const handleFoodCoAuth = useCallback(async () => {
    await handleAuth(foodCoAuthUrl);
  }, [handleAuth]);

  const handleLogout = useCallback(async () => {
    await AsyncStorage.removeItem(tokenStorageName);
    Linking.openURL('/');
  }, []);

  return {
    isAuthenticated: state.isAuthenticated,
    token: state.token,
    handleGoogleAuth,
    handleFoodCoAuth,
    handleLogout,
  };
}

interface AuthState {
  isAuthenticated: boolean | null;
  token: string | null;
}

interface AuthAction {
  type: 'authenticated' | 'notAuthenticated';
  token?: AuthState['token'];
}

function authReducer(_state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'authenticated':
      return { isAuthenticated: true, token: action.token ?? null };
    case 'notAuthenticated':
      return { isAuthenticated: false, token: null };
    default:
      throw new Error();
  }
}

const authInitialState = {
  isAuthenticated: null,
  token: null,
};
