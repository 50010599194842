import { useCallback, useMemo } from 'react';
import { Box, useMediaQuery, Text, Stack } from 'native-base';

import { Header } from '@sizeup/components';
import { PageWithMenu } from '@sizeup/templates';
import { ListItem } from '@sizeup/components';
import { MainStackNavigationProps } from '@sizeup/navigation';
import { toCurrency, toPercentage } from '@sizeup/utils';
import { trpc } from '../../utils/trpc';

import {
  ProductsList,
  ProductForm,
  ProductIngredientsForm,
} from './components';
import { useProduct } from './hooks';

export function ProductUpdate({
  navigation: { navigate },
  route: { params },
}: MainStackNavigationProps<'ProductUpdate'>) {
  const productId = params?.id;
  const [isLandscape] = useMediaQuery([{ orientation: 'landscape' }]);
  const { product, fetching } = useProduct({ productId });
  const updateProduct = useUpdateProduct();
  const defaultValues = useDefaultValues({ product });
  const handleSave = useCallback(
    async (
      values: Omit<
        Parameters<typeof updateProduct>[0],
        'productId' | 'listingLevel'
      >
    ) => {
      await updateProduct({
        name: values.name,
        listingLevel: 'primary',
        category: values.category,
        salesPrice: values.salesPrice,
        numberOfServings: values.numberOfServings,
        preparationTimeInMinutes: values.preparationTimeInMinutes,
        recipeYield: values.recipeYield,
        recipeYieldDimension: values.recipeYieldDimension,
        recipeDirections: values.recipeDirections || '',
        productId,
      });
      navigate('ProductUpdate', { id: productId });
    },
    [updateProduct, productId, navigate]
  );

  return (
    <PageWithMenu selectedMenuOption="Products">
      {isLandscape && (
        <Box flex={1}>
          <Header title={'Cardápio'} />
          <ProductsList selectedProductId={productId} />
        </Box>
      )}

      <Box flex={2} backgroundColor="white" shadow={10}>
        <Header title={product ? `Editar ${product.name}` : 'Aguarde...'} />
        {product && defaultValues ? (
          <ProductForm
            defaultValues={defaultValues}
            onSave={handleSave}
            header={<ProductDisplayValues product={product} />}
            disabled={fetching}
          >
            <ProductIngredientsForm productId={productId} />
          </ProductForm>
        ) : (
          <>Loading...</>
        )}
      </Box>
    </PageWithMenu>
  );
}

function useDefaultValues({
  product,
}: Pick<ReturnType<typeof useProduct>, 'product'>) {
  return useMemo(() => {
    if (!product) {
      return null;
    }
    return {
      name: product.name,
      category: product.category || '',
      numberOfServings: product.numberOfServings ?? null,
      preparationTimeInMinutes: product.preparationTimeInMinutes ?? null,
      recipeDirections: product.recipeDirections || '',
      recipeYield: product.recipeYield ?? null,
      recipeYieldDimension: product.recipeYieldDimension || '',
      salesPrice: product.salesPrice ?? null,
    };
  }, [product]);
}

function useUpdateProduct() {
  const mutation = trpc.product.update.useMutation();
  return mutation.mutateAsync;
}

const ProductDisplayValues = ({
  product,
}: {
  product: ReturnType<typeof useProduct>['product'];
}) => {
  const [isPortrait] = useMediaQuery([
    {
      orientation: 'portrait',
    },
  ]);

  let costOfGoodsSold = '';
  if (product) {
    costOfGoodsSold = toPercentage(product.costOfGoodsSold);
  }
  let costPrice = '';
  if (product) {
    costPrice = toCurrency(product.costPrice);
  }
  return (
    <Stack mt={4} direction={isPortrait ? 'column' : 'row'}>
      <Box mx={7} my={isPortrait ? 0 : 2} flex={1}>
        <ListItem
          text={<Text fontWeight="bold">CMV</Text>}
          subText="Custo da mercadoria vendida"
          rightText={
            <Text testID="costOfGoodsSoldDisplay">{costOfGoodsSold}</Text>
          }
          scoreColor="success.500"
          backgroundColor="muted.50"
        />
      </Box>
      <Box mx={7} my={isPortrait ? 0 : 2} flex={1}>
        <ListItem
          text={<Text fontWeight="bold">Custo da receita</Text>}
          rightText={<Text testID="costPriceDisplay">{costPrice}</Text>}
          scoreColor="success.500"
          backgroundColor="muted.50"
        />
      </Box>
    </Stack>
  );
};
