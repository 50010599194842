import { forwardRef } from 'react';
import type { ComponentProps } from 'react';
import { Input } from 'native-base';

type NumberInputProps = Omit<
  ComponentProps<typeof Input>,
  'value' | 'onChangeText'
> & {
  value: unknown;
  onChangeText: (value: unknown) => void;
};

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => {
    let validValue;
    if (typeof props.value === 'number') {
      validValue = String(props.value);
    } else if (typeof props.value === 'string') {
      validValue = props.value;
    } else {
      validValue = '';
    }
    return (
      <Input
        ref={ref ?? undefined}
        {...props}
        onChangeText={(value) =>
          props.onChangeText(value !== '' ? value : null)
        }
        value={validValue}
      />
    );
  }
);
