import { Button, useContrastText } from 'native-base';

interface PillProps {
  children: React.ReactElement | string;
  selected?: boolean;
  onPress?: () => void;
}

export function Pill({ selected, onPress, children }: PillProps) {
  const colorScheme = selected ? 'accent' : 'mutedLight';
  const textColor = useContrastText('accent.900');
  return (
    <Button
      size="xs"
      rounded="20"
      h={30}
      mb={2}
      mr={2}
      colorScheme={colorScheme}
      _text={{
        _light: { color: selected ? textColor : 'muted.900' },
        _dark: { color: selected ? textColor : 'muted.50' },
        fontSize: 'xs',
        fontWeight: 'bold',
      }}
      onPress={onPress}
      accessibilityRole="checkbox"
      accessibilityState={{ checked: selected }}
    >
      {children}
    </Button>
  );
}
