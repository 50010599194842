import { trpc } from '../../../utils/trpc';

export function usePurchases() {
  const { data, isLoading, isFetching, error } =
    trpc.purchase.getList.useQuery();
  return {
    purchases: data,
    fetching: isFetching,
    loading: isLoading,
    empty: !data?.length,
    error,
  };
}
