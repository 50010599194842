import {
  Box,
  Stack,
  useColorModeValue,
  useBreakpointValue,
  useMediaQuery,
} from 'native-base';
import { StatusBar } from 'expo-status-bar';

import { Menu } from '@sizeup/components';
import { MainStackNavigationType } from '../../navigation/types';

interface PageWithMenu {
  selectedMenuOption: keyof MainStackNavigationType;
  showMenuIfPortrait?: boolean;
  children?: React.ReactNode;
}

export function PageWithMenu({
  showMenuIfPortrait = false,
  selectedMenuOption,
  children,
}: PageWithMenu) {
  const backgroundColor = useColorModeValue('light.50', 'dark.50');

  const direction = useBreakpointValue({
    base: 'column',
    sm: 'row-reverse',
  });

  const [isPortrait] = useMediaQuery([
    {
      orientation: 'portrait',
    },
  ]);

  const showMenu = !isPortrait || (isPortrait && showMenuIfPortrait);

  return (
    <>
      <StatusBar style="auto" />
      <Box flex={1} backgroundColor={backgroundColor} flexDirection={direction}>
        <Stack direction="row" flex={1}>
          {children}
        </Stack>
        {showMenu && <Menu selectedOption={selectedMenuOption} />}
      </Box>
    </>
  );
}
