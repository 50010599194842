import { PageWithMenu } from '@sizeup/templates';

import { HomeEmptyState } from './components/HomeEmptyState';

export function Home() {
  return (
    <PageWithMenu selectedMenuOption="Home" showMenuIfPortrait>
      <HomeEmptyState />
    </PageWithMenu>
  );
}
