import { useState, useCallback } from 'react';
import { Text, SectionList } from 'native-base';

import type { PurchaseItemType } from '@sizeup/types';
import {
  AlertIcon,
  ListItem,
  ListLoadingSkeleton,
  EmptyStateFillerImage,
} from '@sizeup/components';
import { toCurrency } from '@sizeup/utils';

import { ConciliationModal } from '../../Conciliation/components/ConciliationModal';
import { usePurchaseDetail } from '../hooks';

interface PurchasesListProps {
  selectedPurchaseId: string;
}

export function PurchaseItemsList({ selectedPurchaseId }: PurchasesListProps) {
  const { purchase, fetching } = usePurchaseDetail({
    purchaseId: selectedPurchaseId,
  });

  const [selectedItem, setSelectedItem] = useState<PurchaseItemType | null>(
    null
  );

  const renderPurchaseItem = useCallback(
    ({ item }: { item: PurchaseItemType }) => (
      <ListItem
        testID={`purchase-item-${item.purchaseItemId}`}
        text={`${item.customName || item.name || ''}`}
        rightText={
          item.quantity && item.unitCost
            ? toCurrency(item.quantity * item.unitCost)
            : undefined
        }
        rightSubText={
          item.quantity || item.dimension
            ? `${item.quantity} ${item.dimension}`
            : undefined
        }
        rightSubIcon={item.errors ? <AlertIcon /> : undefined}
        onPress={() => {
          if (item.errors) {
            setSelectedItem(item);
          }
        }}
      />
    ),
    [setSelectedItem]
  );

  const renderSectionHeader = useCallback(
    ({ section: { title } }: { section: { title: string } }) => (
      <Text p={7} fontWeight="bold">
        {title}
      </Text>
    ),
    []
  );

  const keyExtractor = useCallback(
    (item: Pick<PurchaseItemType, 'purchaseItemId'>) => item.purchaseItemId,
    []
  );

  if (fetching) {
    return <ListLoadingSkeleton />;
  }

  if (!purchase?.items?.length) {
    return <EmptyStateFillerImage image="Purchases" />;
  }

  return (
    <>
      <SectionList
        flex={1}
        sections={[{ title: 'Insumos', data: purchase.items ?? [] }]}
        refreshing={fetching}
        keyExtractor={keyExtractor}
        renderItem={renderPurchaseItem}
        renderSectionHeader={renderSectionHeader}
      />
      {selectedItem && (
        <ConciliationModal
          purchaseId={selectedPurchaseId}
          item={selectedItem}
          onClosed={() => setSelectedItem(null)}
        />
      )}
    </>
  );
}
