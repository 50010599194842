import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  Text,
  VStack,
  useBreakpointValue,
} from 'native-base';
import { StatusBar } from 'expo-status-bar';

import { LogoImageAuth } from '@sizeup/theme/images';
import SizeupLogoSvg from '../../assets/svgs/SizeupLogoSvg';
import { useEnabledAuthProviders } from './useEnabledAuthProviders';

const CARD_HEIGHT = 600;
const CARD_WIDTH = 1200;

const FoodcoSignupIcon = require('../../assets/ui/icon/extra/foodco.png'); // eslint-disable-line @typescript-eslint/no-var-requires
const GoogleSignupIcon = require('../../assets/ui/icon/extra/google.png'); // eslint-disable-line @typescript-eslint/no-var-requires
const HeroImage = require('../../assets/heroImage.png'); // eslint-disable-line @typescript-eslint/no-var-requires

export function AuthForm({
  onGoogleLoginPress,
  onFoodCoLoginPress,
}: {
  onGoogleLoginPress: () => void;
  onFoodCoLoginPress: () => void;
}): JSX.Element {
  const enabledAuthProviders = useEnabledAuthProviders();
  // Responsive values for top box "card" that respects base and lg screen sizes.
  const responsive = useBreakpointValue({
    base: {
      flexDirection: 'column',
      w: '100%',
    },
    lg: {
      flexDirection: 'row',
      w: '80%',
      maxHeight: CARD_HEIGHT,
      maxWidth: CARD_WIDTH,
    },
  });

  return (
    <>
      <StatusBar style="light" />
      <Center flex={1} backgroundColor="primary.600">
        <Box
          flex={1}
          rounded="3xl"
          overflow="hidden"
          alignItems="center"
          shadow="2"
          {...responsive}
        >
          <Box
            safeAreaTop
            flex={1}
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="primary.500"
            h="100%"
            w="100%"
          >
            <Image
              source={LogoImageAuth}
              resizeMode="contain"
              w={180}
              h={70}
              m={6}
              alt="Logo"
            />
            <Image
              source={HeroImage}
              h={455}
              w={405}
              alt="Ilustração de mulher com smartphone em mãos"
            />
          </Box>
          {/* // TODO: Extract this VStack props as a color-scheme aware content component */}
          <VStack
            safeAreaBottom
            flex={1}
            w="100%"
            h="100%"
            _light={{ bg: 'light.50' }}
            _dark={{ bg: 'dark.50' }}
          >
            <Center flex={1} w="100%" flexGrow={1}>
              <Heading size="xl" m={2}>
                Ficha técnica
              </Heading>
              <Text fontSize="lg" textAlign="center" m={5}>
                Seu cardápio mais{'\n'}inteligente e rentável.
              </Text>
              <Center p={5}>
                {enabledAuthProviders.google ? (
                  <Button
                    onPress={onGoogleLoginPress}
                    variant="outline"
                    borderRadius="full"
                    px={6}
                    py={4}
                    mb={4}
                    leftIcon={
                      <Image
                        resizeMode="contain"
                        source={GoogleSignupIcon}
                        size={26}
                        alt="icon"
                      />
                    }
                  >
                    <Text fontWeight="bold" fontSize="sm" mx={5}>
                      Entrar com Google
                    </Text>
                  </Button>
                ) : null}
                {enabledAuthProviders.foodco ? (
                  <Button
                    onPress={onFoodCoLoginPress}
                    variant="outline"
                    borderRadius="full"
                    px={6}
                    py={4}
                    leftIcon={
                      <Image
                        resizeMode="contain"
                        source={FoodcoSignupIcon}
                        size={26}
                        alt="icon"
                      />
                    }
                  >
                    <Text fontWeight="bold" fontSize="sm" mx={5}>
                      Entrar com FoodCo
                    </Text>
                  </Button>
                ) : null}
              </Center>
            </Center>
            <Center h={52} mb={5}>
              <Heading size="xs">um produto</Heading>
              <SizeupLogoSvg />
            </Center>
          </VStack>
        </Box>
      </Center>
    </>
  );
}
