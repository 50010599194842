import { Box, useMediaQuery } from 'native-base';

import { Header } from '@sizeup/components';
import { PageWithMenu } from '@sizeup/templates';
import { MainStackNavigationProps } from '@sizeup/navigation';
import { toCurrency } from '@sizeup/utils';

import { PurchasesList, PurchaseItemsList } from './components';
import { usePurchaseDetail } from './hooks';

export function PurchaseDetail({
  route,
}: MainStackNavigationProps<'PurchaseDetail'>) {
  const {
    params: { id: purchaseId },
  } = route;
  const { purchase } = usePurchaseDetail({ purchaseId });
  const [isLandscape] = useMediaQuery([{ orientation: 'landscape' }]);
  return (
    <PageWithMenu selectedMenuOption="Purchases">
      {isLandscape && (
        <Box flex={1}>
          <Header title={'Compras'} />
          <PurchasesList selectedPurchaseId={purchaseId} />
        </Box>
      )}

      <Box flex={2} backgroundColor="white" shadow={10}>
        <Header
          title={purchase?.supplierName || 'Compra'}
          subTitle={purchase?.totalCost ? toCurrency(purchase.totalCost) : ''}
        />
        <PurchaseItemsList selectedPurchaseId={purchaseId} />
      </Box>
    </PageWithMenu>
  );
}
