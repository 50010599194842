import { z } from 'zod';

export const stringAsNumber = () =>
  z.preprocess(
    (args) => {
      if (args === '') {
        return NaN;
      } else if (typeof args === 'string') {
        return args.replace(',', '.');
      }
      return args;
    },
    z.coerce.number({ invalid_type_error: 'Value must be a number' })
  );

export const notEmptyString = () =>
  z.string().min(1, { message: 'Required field.' });

export const stringOrNull = () =>
  z.preprocess(
    (args) => (args === '' ? null : args),
    z.coerce.string().nullable()
  );
