export default {
  prefixes: ['sizeup://', 'https://*.sizeup.app', 'https://*.foodco.com.br/'],
  config: {
    screens: {
      Auth: '',
      Home: '/home',
      Profile: '/profile',
      Purchases: '/compras',
      PurchaseCreate: '/compras/nova',
      PurchaseDetail: '/compras/:id',
      AddPurchaseItems: '/nova-compra/:id',
      Products: '/produtos',
      ProductCreate: '/produto/novo',
      ProductUpdate: '/produto/:id?',
    },
  },
};
