import { useMemo } from 'react';
import { toCurrency } from '@sizeup/utils';
import { trpc } from '../../../utils/trpc';
import type { RouterOutput, RouterInput } from '../../../utils/trpc';

interface Data {
  type: 'ingredient' | 'subproduct';
  instance:
    | RouterOutput['product']['getOne']['ingredients'][number]
    | RouterOutput['product']['getOne']['subproducts'][number];
  id: string;
  name: string;
  totalCost: string;
  quantity: string;
  errors: string[] | undefined;
}

export function useIngredientsAndSubproducts({
  productId,
}: Pick<RouterInput['product']['getOne'], 'productId'>) {
  const { data, error, isLoading, isFetching } = trpc.product.getOne.useQuery({
    productId,
  });

  const allData = useMemo<Data[]>(() => {
    const ingredients = prepareIngredients(data?.ingredients ?? []);
    const subproducts = prepareSubproducts(data?.subproducts ?? []);
    return [...ingredients, ...subproducts];
  }, [data]);

  return {
    fetching: isFetching,
    loading: isLoading,
    empty: !allData.length,
    error: error,
    data: allData,
  };
}

function prepareIngredients(
  ingredients: RouterOutput['product']['getOne']['ingredients']
) {
  return ingredients.map((instance) => ({
    type: 'ingredient' as const,
    instance,
    id: instance.name,
    name: instance.name,
    totalCost:
      instance.initialQuantity && instance.unitCost
        ? toCurrency(instance.initialQuantity * instance.unitCost)
        : '',
    quantity:
      instance.initialQuantity && instance.dimension
        ? `${instance.initialQuantity} ${instance.dimension}`
        : '',
    errors: instance.errors,
  }));
}

function prepareSubproducts(
  subproducts: RouterOutput['product']['getOne']['subproducts']
) {
  return subproducts.map((instance) => ({
    type: 'subproduct' as const,
    instance,
    id: instance.subproductId,
    name: instance.name,
    totalCost:
      instance.quantity && instance.unitCost
        ? toCurrency(instance.quantity * instance.unitCost)
        : '',
    quantity: instance.quantity
      ? `${instance.quantity} ${instance.dimension ?? 'un'}`
      : '',
    errors: undefined,
  }));
}
