import { trpc } from '../../../utils/trpc';

interface UseProductsProps {
  listingLevel: 'primary' | 'secondary';
  category?: string;
}

export function useProducts(filter?: UseProductsProps) {
  const { data, error, isFetching, isLoading } = trpc.product.getList.useQuery(
    filter ? { filter } : undefined
  );
  return {
    products: data,
    fetching: isFetching,
    loading: isLoading,
    empty: !data?.length,
    error,
  };
}
