import { useMemo } from 'react';
import { Button, Text, Box, VStack, HStack } from 'native-base';
import jwtDecode from 'jwt-decode';
import { PageWithMenu } from '@sizeup/templates';
import { Header } from '@sizeup/components';
import { useAuthToken, useLogout } from '../Auth';

export function Profile() {
  const token = useAuthToken();
  const logoutHandler = useLogout();
  const user = useMemo(() => getUserFromToken(token), [token]);
  return (
    <PageWithMenu selectedMenuOption="Profile" showMenuIfPortrait>
      <Box flex={1}>
        <Header title={'Perfil'} />
        <VStack mx={7}>
          <Text>Olá {user}, obrigado por usar o App hoje!</Text>
          <HStack my={7}>
            <Button onPress={logoutHandler}>Sair</Button>
          </HStack>
        </VStack>
      </Box>
    </PageWithMenu>
  );
}

interface SizeupToken {
  properties: {
    name: string;
    email: string;
    userId: string;
  };
}

function getUserFromToken(token: string | null) {
  if (!token) {
    return null;
  }
  const tokenData = jwtDecode<SizeupToken>(token);
  const userData = tokenData.properties;
  return userData.name || userData.email || `usuário ${userData.userId}`;
}
