import { trpc } from '../../../utils/trpc';

export function usePurchaseDetail({ purchaseId }: { purchaseId: string }) {
  const { data, isLoading, error } = trpc.purchase.getOne.useQuery({
    purchaseId,
  });
  return {
    purchase: data,
    fetching: isLoading,
    error,
  };
}
